@import "../global.scss";


.e_learn_banner {
    background: url("../../Assets/Images/Home/learn-howbg.webp") no-repeat, $pale_goldenrod;
    background-size: contain;
    background-position: center;
}

.e_works_banner {
    background: url("../../Assets/Images/Home/works.webp") no-repeat, $lavender_blue_light;
    background-size: contain;
    background-position: center;
}