@charset "UTF-8";
/*
 *   File : global.scss
 *   Author : https://evoqins.com
 *   Description : SCSS file
 *   Integrations : NA
 *   Version : 1.0.0
*/

// @import "../Style/style.scss";
@import "../Style/font.scss";
@import "../Style/responsive.scss";
@import "../Style/color.scss";

/*--------- Font family class ----------- */


.e-body::-webkit-scrollbar {
    display: none !important;
}

.e-primary-font-inter {
    font-family: "Inter";
}

.e-alt-font-poppins {
    font-family: "Poppins";
}

/*--------- Font weight------------ */
.e-font-weight-200 {
    font-weight: 200 !important;
}

.e-font-weight-300 {
    font-weight: 300 !important;
}

.e-font-weight-400 {
    font-weight: 400 !important;
}

.e-font-weight-500 {
    font-weight: 500 !important;
}

.e-font-weight-600 {
    font-weight: 600 !important;
}

.e-font-weight-700 {
    font-weight: 700 !important;
}

.e-font-weight-800 {
    font-weight: 800 !important;
}

.e-font-weight-900 {
    font-weight: 900 !important;
}

/* -------Font color ----------- */

.e-text-white {
    color: $white;
}

.e-text-black {
    color: $black;
}

.e-text-dark-charcoal {
    color: $dark_charcoal;
}

.e-text-charleston-green {
    color: $charleston_green;
}

.e-text-emerald {
    color: $emerald;
}

.e-text-onyx {
    color: $onyx;
}

.e-text-onyx-80per {
    color: $onyx-80per;
}

.e-text-egyptian-blue {
    color: $egyptian_blue;
}

.e-text-rich-black {
    color: $rich_black;
}

.e-text-yankees-blue {
    color: $yankees_blue;
}

.e-text-slate-gray {
    color: $slate_gray;
}

.e-text-ufo-green {
    color: $ufo_green;
}

.e-text-english-vermillion {
    color: $english_vermillion;
}

.e-text-dark-liver {
    color: $dark_liver;
}

.e-text-sonic-silver {
    color: $sonic_silver;
}

.e-text-sea-green {
    color: $sea_green;
}

.e-text-light-silver {
    color: $light_silver;
}

.e-text-lavender-gray {
    color: $lavender_gray;
}

.e-text-ochre {
    color: $ochre;
}

.e-text-nickel {
    color: $nickel;
}

.e-text-go-green {
    color: $go_green;
}

.e-text-lotion {
    color: $lotion;
}

.e-text-sunset-orange {
    color: $sunset_orange;
}

.e-text-mustard-yellow {
    color: $mustard_yellow;
}

.e-text-orange-yellow {
    color: $orange_yellow;
}

.e-text-raisin-black {
    color: $raisin_black;
}

.e-text-red {
    color: $red;
}

.e-text-indigo {
    color: $indigo;
}

.e-text-full-red {
    color: $full_red;
}

.e-text-medium-orchid {
    color: $medium_orchid;
}

.e-text-dark-tangerine {
    color: $dark_tangerine;
}

.e-text-citron {
    color: $citron;
}

.e-text-indian-yellow {
    color: $indian_yellow
}

.e-text-chinese-black {
    color: $chinese_black;
}

.e-text-azureish-white {
    color: $azureish_white;
}

.e-text-dark-jungle-green {
    color:$dark_jungle_green;
}

.e-text-dark-liver-70per {
    color: $dark_liver_70per;
}

.e-text-davys-grey {
    color: $davys_grey;
}


/*===============================
        caret-color
================================= */

.e-caret-color-egyptian-blue {
    caret-color: $egyptian_blue;
}

.e-caret-color-transparent {
    caret-color: transparent;
}

/*===============================
        Background color 
================================= */
.e-bg-transparent {
    background: transparent;
}

.e-bg-white {
    background: $white !important;
}

.e-bg-lotion {
    background: $lotion !important;
}

.e-bg-egyptian-blue {
    background: $egyptian_blue;
}

.e-bg-bright-gray {
    background: $bright_gray;
}

.e-bg-anti-flash-white {
    background: $anti_flash_white;
}

.e-bg-anti-flash-white-light {
    background: $anti_flash_white_light;
}

.e-bg-anti-flash-light-white {
    background: $anti_flash_light_white;
}

.e-bg-alice-blue {
    background: $alice_blue;
}

.e-bg-royal-purple {
    background: $royal_purple;
}

.e-bg-cultured {
    background: $cultured;
}

.e-bg-emerald {
    background: $emerald;
}

.e-bg-lavender {
    background: $lavender;
}

.e-bg-lavender-gray {
    background: $lavender_gray;
}

.e-bg-pale-goldenrod {
    background: $pale_goldenrod;
}

.e-bg-nyanza {
    background: $nyanza;
}

.e-bg-cyan-azure {
    background: $cyan_azure;
}

.e-bg-cornsilk {
    background: $cornsilk;
}

.e-bg-magnolia {
    background: $magnolia;
}

.e-bg-nickel {
    background: $nickel;
}

.e-bg-nyanza-green {
    background: $nyanza_green;
}

.e-bg-columbia-blue {
    background: $columbia_blue;
}

.e-bg-light-blue {
    background: $light_blue;
}

.e-bg-light-gray {
    background: $light_gray;
}

.e-bg-misty-rose {
    background: $misty_rose;
}

.e-bg-blanched-almond {
    background: $blanched_almond;
}

.e-bg-go-green {
    background: $go_green;
}

.e-bg-lavender-blue {
    background: $lavender_blue;
}

.e-bg-chinese-white {
    background: $chinese_white;
}

.e-bg-light-cobalt-blue {
    background: $light_cobalt_blue;
}

.e-bg-light-blue-opacity-04 {
    background: $light_blue_opacity_04;
}

.e-bg-anti-flash-white-dark {
    background: $anti_flash_white_dark;
}

.e-bg-medium-orchid {
    background: $medium_orchid !important;
}

.e-bg-floral-white {
    background: $floral_white;
}

.e-bg-dark-tangerine {
    background: $dark_tangerine;
}

.e-bg-honeydew {
    background: $honeydew;
}

.e-bg-ufo-green {
    background: $ufo_green;
}

.e-bg-beige {
    background: $beige;
}

.e-bg-dark-lemon-lime {
    background: $dark_lemon_lime;
}

.e-bg-orange-yellow-opacity-06 {
    background: $orange_yellow_opacity_06;
}

.e-bg-magnolia-dark {
    background: $magnolia_dark;
}

.e-bg-snow {
    background: $snow;
}

.e-bg-sunset-orange {
    background: $sunset_orange;
}

.e-bg-profile-tab {
    background: rgba(219, 232, 254, 0.50);
}

.e-bg-cosmic-latte {
    background: $cosmic_latte;
}

.e-bg-lavender-blue-dark {
    background: $lavender_blue_dark;
}


/*===============================
        Background gradient 
================================= */

.e-login-gradient {
    background: linear-gradient(192deg, $lavender 50.5%, #EADEFF 100%), $lotion;
}

.e-banner-gradient {
    background: linear-gradient(180deg, #156DBA 0%, $ateneo_blue 100%);
}

.e-portfolio-gradient {
    background: radial-gradient(346.13% 134.82% at 96.95% 100%, #1C359F 0%, #1A2B75 100%);
}

.e-profile-gradient {
    background: linear-gradient(192deg, $lavender 49.89%, #EADEFF 98.8%), #FFF;
}

// ellipse 

.e-ellipse {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.e-ellipse-single {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

// Word breal 
.break-word {
    word-wrap: break-word;
}

//toast error
.e-toast {
    font-family: "Inter";
    color: $charleston_green;
    font-size: 0.85rem;
    font-weight: 400;
    line-height: 1.5rem;
}

// Toogle buttom 
.form-switch .form-check-input {
    background-image: url("../Assets/Images/toogle_bg.svg");
    background-color: #D5D6DE;
    background-position: 0.1rem center;
    border: 0.063rem solid transparent;
}

.form-switch .form-check-input:checked {
    background-color: #1b2f84;
    border-color: #fff;
}


// Hover and Animations

.hover-shadow-card,
.arrow-animation {
    transition: all 0.3s;
}

.hover-shadow-card:hover {
    transform: translateY(-0.15rem);
    box-shadow: 0 0.25rem 0.688rem rgba(0, 0, 0, 0.15);

    & .arrow-animation {
        transform: translateX(0.375rem);
    }
}


// Modal

.modal {
    --bs-modal-border-radius: 1.5rem;
}

.modal::-webkit-scrollbar {
    display: none;
}

.modal .btn-close {
    background: url("../Assets/Images/close-btn.svg") no-repeat;
}

// Input Radio
.input-radio {
    accent-color: #1C359F;
    width: 1.25rem;
    border: 20px solid red;
}

.hover-arrow-wrapper {
    .arrow {
        transition: all 0.3s ease-in-out;
    }
    
    &:hover {
        .arrow {
            transform: translateX(0.375rem);
        }

    }
}