@import "../color.scss";

.e_login_wrapper,
.e_forget_pin_wrapper,
.e_unlock_wrapper {
    background: url("../../Assets/Images/Login/login-signup-bg-image.webp"), $lotion;
    background-position: start !important;
    background-size: 470px 460px, 100% !important;
    // background-size: contain, 100% !important;
    background-repeat: no-repeat !important;
    box-shadow: 0px 0px 2px 0px #011EF738;
    margin-inline: 2px;
}

.e_login_wrapper {
    padding: 46px 50px;
}

.e_unlock_wrapper {
    padding: 90px 50px;
}

.e_forget_pin_wrapper {
    padding: 0px 3.125rem;
    height: 31.25rem;
}

@media only screen and (max-width: 768px) {
    .e_login_wrapper,
    .e_forget_pin_wrapper,
    .e_unlock_wrapper {
        background: $lotion;
        padding: 32px;
    }

    .e_forget_pin_wrapper {
        padding: 3rem 3.125rem;
        height: auto;
     }
}

@media only screen and (max-width: 576px) {
    .e_login_wrapper,
    .e_forget_pin_wrapper,
    .e_unlock_wrapper {
        padding: 24px;
    }
}


