/*File : color.scss
*   Author : https://evoqins.com
*   Description : SCSS file for define and export colors
*   Integrations : NA
*   Version : 1.0.0
*/

// Color declaration
$white: #ffffff;
$black: #000000;
$lotion: #FCFCFD;
$light_gray: #CED4DA;
$dark_charcoal: #323232;
$egyptian_blue: #1C359F;
$charleston_green: #23262F;
$onyx: #353945;
$onyx-80per: #353945CC;
$bright_gray: #E6E8EC;
$red: #f6172d;
$anti_flash_white: #F0F0F9;
$anti_flash_light_white: #F3F3F2;
$azureish_white: #DDDDF1;
$rich_black: #040505;
$yankees_blue: #222B38;
$slate_gray: #777E90;
$sonic_silver: #757575;
$alice_blue: #EFF5FF;
$royal_purple: #804ABF;
$ufo_green: #37D660;
$nyanza_green : #ECFCE5;
$go_green : #0DBF66;
$english_vermillion: #CF4444;
$dark_liver: #4F4F4F;
$cultured: #F4F6F9;
$emerald: #52D273;
$lavender: #E5E5FE;
$sea_green: #1C9646;
$pale_goldenrod: #F2EBAC;
$light_silver: #D0D5DD;
$lavender_gray: #C6C6C8;
$denim_blue: #213FBC;
$nyanza: #DCFFE2;
$lavender_gray : #C5C5C9;
$cyan_azure : #567DB7;
$ateneo_blue: #084070;
$cornsilk: #FFF3DE;
$ochre: #C7851E;
$magnolia: #F3ECFC;
$nickel: #727272;
$chinese_black: #181614;
$columbia_blue : #CBDFEF;
$sunset_orange : #FF6551;
$mustard_yellow : #DCAF06;
$blanched_almond : #F9EFC9;
$sunset-orange : #FF6551;
$misty_rose: #FEE2E2;
$raisin_black: #212121;
$lavender_blue: #D1D6FF;
$light_gray_opacity_05: hsla(240, 6%, 83%, 0.5);
$indigo: #293B75;
$anti_flash_white_light: #EEF2F6;
$chinese_white: #E4E0DD;
$full_red: #F00;
$iguana_green: #6EC885;
$orange_yellow: #F4D064;
$fuzzy_wuzzy_red: #D76A66;
$light_cobalt_blue: #8B9EEB;
$light_blue_opacity_04 :rgba(219, 232, 254, 0.40);
$light_blue_opacity_05 :rgba(219, 232, 254, 0.50);
$azureish_white_2 :#dbe8fe;
$light_blue :rgba(219, 232, 254);
$anti_flash_white_dark: #F5EBFA;
$medium_orchid: #C34AFB;
$floral_white: #FFFBED;
$dark_tangerine: #FCA30D;
$honeydew: #EEFFF3;
$citron: #84A427;
$beige: #F2FBD7;
$dark_lemon_lime: #85A919;
$indian_yellow: #e99d57;
$orange_yellow_opacity_06: #f8e8b764;
$magnolia_dark: #F9F1FA;
$snow: #FFF6F8;
$philippine_gray : #8C8C8C;
$cosmic_latte : #FFFCE5;
$lavender_blue_light: #BFD8FC;
$lavender_blue_dark: #DFE4FE;
$auro_metal_saurus_gray : #667085;
$platinum_gray : #E6E7E6;
$dark_jungle_green:#101828;
$dark_liver_70per: #4F524FB2;
$davys_grey : #525253;
$cool_light_gray : #D1D1D6;

:export {
    white: $white;
    black: $black;
    lotion: $lotion;
    light_gray: $light_gray;
    dark_charcoal: $dark_charcoal;
    egyptian_blue: $egyptian_blue;
    charleston_green: $charleston_green;
    onyx: $onyx;
    bright_gray: $bright_gray;
    red: $red;
    anti_flash_white: $anti_flash_white;
    azureish_white: $azureish_white;
    rich_black: $rich_black;
    yankees_blue: $yankees_blue;
    slate_gray: $slate_gray;
    sonic_silver: $sonic_silver;
    alice_blue: $alice_blue;
    royal_purple: $royal_purple;
    ufo_green: $ufo_green;
    english_vermillion: $english_vermillion;
    dark_liver: $dark_liver;
    cultured: $cultured;
    emerald: $emerald;
    lavender: $lavender;
    sea_green: $sea_green;
    pale_goldenrod: $pale_goldenrod;
    light_silver: $light_silver;
    lavender_gray: $lavender_gray;
    denim_blue: $denim_blue;
    nyanza: $nyanza;
    ateneo_blue: $ateneo_blue;
    cornsilk: $cornsilk;
    ochre: $ochre;
    magnolia: $magnolia;
    nickel: $nickel;
    chinese_black: $chinese_black;
    columbia_blue: $columbia_blue;
    sunset-orange: $sunset_orange;
    mustard_yellow: $mustard_yellow;
    blanched_almond: $blanched_almond;
    misty_rose: $misty_rose;
    raisin_black: $raisin_black;
    lavender_blue: $lavender_blue;
    light_gray_opacity_05: $light_gray_opacity_05;
    indigo: $indigo;
    chinese_white: $chinese_white;
    full_red: $full_red;
    iguana_green: $iguana_green;
    orange_yellow: $orange_yellow;
    fuzzy_wuzzy_red: $fuzzy_wuzzy_red;
    light_cobalt_blue: $light_cobalt_blue;
    light_blue: $light_blue;
    anti_flash_white_dark: $anti_flash_white_dark;
    medium_orchid: $medium_orchid;
    floral_white: $floral_white;
    dark_tangerine: $dark_tangerine;
    honeydew: $honeydew;
    citron: $citron;
    beige: $beige;
    dark_lemon_lime: $dark_lemon_lime;
    indian_yellow: $indian_yellow;
    orange_yellow_opacity_06: $orange_yellow_opacity_06;
    magnolia_dark: $magnolia_dark;
    snow: $snow;
    cosmic_latte : $cosmic_latte;
    ochre:$ochre;
    cosmic_latte: $cosmic_latte;
    lavender_blue_light: $lavender_blue_light;
    lavender_blue_dark: $lavender_blue_dark;
    auro_metal_saurus_gray:$auro_metal_saurus_gray;
    cool_light_gray:$cool_light_gray;
}
