@import "../global.scss";

.e-calender {
    z-index: 999;

    .react-calendar {
        width: auto !important;
        font-family: "Poppins";
        background: $white !important;
        border-radius: 0.75rem;
        padding: 1rem;
        border: none;
        box-shadow: 0 0.5rem 0.625rem -0.375rem rgba(0, 0, 0, 0.1), 0 1.25rem 1.563rem -0.313rem rgba(0, 0, 0, 0.1);
    }
}

.react-calendar__tile--hasActive {
    background: $egyptian_blue !important;
    color: $white;
}

.react-calendar__month-view {
    background: linear-gradient(192deg, $lavender 49.89%, #EADEFF 98.8%), #FFF;
    border-radius: 12px;
    padding: 8px;
}


.react-calendar__tile:enabled:hover {
    background-color: $cyan_azure;
    color: $white;
}

.react-calendar__tile--active {
    background-color: $egyptian_blue !important;
    color: $white !important;
}

.react-calendar__navigation {
    .react-calendar__navigation__label__labelText {
        font-size: 16px;
        font-weight: 600;
    }
}

.react-calendar__month-view__weekdays {
    font-size: 14px;
    font-weight: 600;

    abbr {
        text-decoration: none;

    }
}

.react-calendar__month-view__days {

    abbr {
        font-size: 16px;
        font-weight: 400;
    }
}

.react-calendar__tile--now {
    background: transparent;
}

.e_input_wrapper {
    border-radius: 0.75rem;
    // border: 0.063rem solid $charleston-green !important;
    font-size: 0.875rem;
    cursor: pointer;
}