/*
 *   File : font.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Index page for font
 *   Integrations :
 *   Version : v1.0
 *   Created : 
 */
/* Import Fonts */

/* Inter font family */
@font-face {
    font-family: 'Inter';
    src: url('../Assets/Font/Inter/static/Inter-Black.ttf');
    font-weight: 900;
}

@font-face {
    font-family: 'Inter';
    src: url('../Assets/Font/Inter/static/Inter-ExtraBold.ttf');
    font-weight: 800;
}

@font-face {
    font-family: 'Inter';
    src: url('../Assets/Font/Inter/static/Inter-Bold.ttf');
    font-weight: 700;
}

@font-face {
    font-family: 'Inter';
    src: url('../Assets/Font/Inter/static/Inter-SemiBold.ttf');
    font-weight: 600;
}

@font-face {
    font-family: 'Inter';
    src: url('../Assets/Font/Inter/static/Inter-Medium.ttf');
    font-weight: 500;
}

@font-face {
    font-family: 'Inter';
    src: url('../Assets/Font/Inter/static/Inter-Regular.ttf');
    font-weight: 400;
}

@font-face {
    font-family: 'Inter';
    src: url('../Assets/Font/Inter/static/Inter-Light.ttf');
    font-weight: 300;
}

@font-face {
    font-family: 'Inter';
    src: url('../Assets/Font/Inter/static/Inter-Thin.ttf');
    font-weight: 200;
}

/* Poppins font family */
@font-face {
    font-family: 'Poppins';
    src: url('../Assets/Font/Poppins/Poppins-Black.ttf');
    font-weight: 800;
}

@font-face {
    font-family: 'Poppins';
    src: url('../Assets/Font/Poppins/Poppins-Bold.ttf');
    font-weight: 700;
}
@font-face {
    font-family: 'Poppins';
    src: url('../Assets/Font/Poppins/Poppins-SemiBold.ttf');
    font-weight: 600;
}
@font-face {
    font-family: 'Poppins';
    src: url('../Assets/Font/Poppins/Poppins-Medium.ttf');
    font-weight: 500;
}
@font-face {
    font-family: 'Poppins';
    src: url('../Assets/Font/Poppins/Poppins-Regular.ttf');
    font-weight: 400;
}
@font-face {
    font-family: 'Poppins';
    src: url('../Assets/Font/Poppins/Poppins-Light.ttf');
    font-weight: 300;
}
@font-face {
    font-family: 'Poppins';
    src: url('../Assets/Font/Poppins/Poppins-Thin.ttf');
    font-weight: 200;
}