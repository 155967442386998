@import "../global.scss";

.e_profile {
    border-radius: 2rem;
    background: #DBE8FE;
    &:hover {
         background: #d1e0fa;
        img:nth-child(2) {
            transition: all 0.5s;
            transform: translateY(0.2rem);
        }
    }
}

//left navigation
.e_nav_item {
    border-radius: 0px 0.75rem 0.75rem 0px;
    background: rgba(219, 232, 254, 0.50);
    border-left: 4px solid #1C359F;
    p{
        color: $egyptian_blue !important;
        font-weight: 500 !important;
    }
}

.e_nav_item_hover {
    transition: all .2s ease-in-out;

    &:hover {
        border-radius: 0px 0.75rem 0.75rem 0px;
        background: rgba(219, 232, 254, 0.50);
    }
}