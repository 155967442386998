@import "../global.scss";



.e_marketing_banner {
    background: url("../../Assets/Images/Home/welcome-banner.webp") no-repeat,;
    background-size: 100% 100%;

    .e_invest_img {
        bottom: -0.5rem;
    }

}

.e_create_account_banner{
    background: url("../../Assets/Images/Home/create-account-banner.webp") no-repeat,;
    background-size: 100% 100%;
}

.e_banner_last {
    background: url("../../Assets/Images/Home/external-portfolio.webp") no-repeat, var(--background);
    background-size: contain;
    background-position: right;
}