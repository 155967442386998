@import "../global.scss";

.e_date_wrapper {
    &:hover {
        background: $cyan_azure;
    }
}

.e_sipdate_input::placeholder {
    color: $lavender_gray;
    font-weight: 500;
    line-height: 1.5rem;
}

.e_profile_modal {
    width: 20.875rem;
}

.e_scrollbar_none ::-webkit-scrollbar {
    display: none;
}

// .e_verify_modal {
//     .e_modal_dialog {
//         width: 28.75rem !important;
//     }
// }

// .e_record_modal {
//     .e_modal_dialog {
//         max-width: 33.625rem !important;
//     }
// }

.e_live_video {
    width: 100%;
    height: 292px;
}

.e_how_works_wrapper {
    background: url("../../Assets/Images/Home/how-it-work-bg.webp") no-repeat, $cultured;
    background-size: cover;
}

.nri_warning_modal_bg {
    background: url("../../Assets/Images/nri-warning-modal-bg.webp") no-repeat, $lotion;
    background-size: 100% 100%;
}

.account_opening_modal_bg {
    background: url("../../Assets/Images/Home/account-opening-modal-bg.webp") no-repeat, $lotion;
    background-size: 100% 100%;
}