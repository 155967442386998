@import "../global.scss";

.e_basket_card {
    background: var(--background);
}

.e_basket_modal_card {
    background: var(--background);
}

.e_fund_card {
    p {
        border: 0.063rem solid $egyptian_blue;
    }
}

.e_border_bottom {
    border-bottom: 0.063rem solid $light_gray;
}

.e_category_card {
     &:hover {
        filter: drop-shadow(0 0.25rem 0.235rem rgba(0, 0, 0, 0.15));
    }
}
// box-shadow: 0px 4px 11px 0px #00000026;


.e_goal_card,
.e_holdings_card,
.e_basket_card,
.e_category_card,
.e_fund_card_wrapper {
    &:hover {
        transform: scale(1) translate3d(0, -3%, 0);
           filter: drop-shadow(0 0.25rem 0.625rem rgba(0, 0, 0, 0.15));

        .e_right_arrow {
            transform: translateX(0.375rem);
        }

        .e_img_wrapper {
        }
    }
}

.e_fund_card_wrapper,
.e_basket_card {
    &:hover {

        filter: drop-shadow(0 0.25rem 0.625rem rgba(0, 0, 0, 0.15));

        .e_right_arrow {
            transform: translateX(0.375rem);
        }
    }
}

.e_sip_card_wrapper {
    &:hover {
        transform: scale(1) translate3d(0, -3%, 0);
        filter: drop-shadow(0 0.25rem 0.625rem rgba(0, 0, 0, 0.15));

        .e_right_arrow {
            transform: translateX(0.375rem);
        }
    }
}