@charset "utf-8";
/* ==============================
   Small desktop
   ============================== */

@import "../Style/font.scss";
@import "../Style/color.scss";

@media only screen and (max-width: 1400px) {}

@media only screen and (max-width: 1200px) {

    .e-life-goal-card-content h3 {
        padding-right: 3.125rem !important;
    }

    .e-goal-card-icon {
        left: -0.5rem !important;
    }

    .height-100px-1200 {
        max-height: 100px;
    }

}

@media only screen and (max-width: 992px) {

    .e-home-wrapper {
        padding-right: 1.5rem !important;
    }

    .e-padding-right-res {
        padding-right: 1.5rem !important;
    }

    .e-padding-48-right-res {
        padding-right: 3rem !important;
    }

    .e-padding-24-right-res {
        padding-right: 1.5rem !important;
    }

    .e-border-radius-TL-BL-24px {
        border-radius: 0 !important;
    }

        .padding-bottom-res {
        padding-bottom: 5rem !important;
    }

    .height-120px-md {
        height : 7.5rem;
    }

    .w-100-md {
        width: 100%;
    }

    #range-picker-inner-wrapper {
        width: 520px !important;
        overflow: scroll;
    }

}

@media only screen and (max-width: 768px) {
    .e-tab-wrapper {
        overflow: auto !important;
        display: flex !important;
        gap: 0 1rem;
        padding-top: 1rem;
        padding-bottom: 0.5rem;

        .e-tab-inactive {
            background: transparent !important;
            padding: 0.5rem 0;
            border-radius: 0 !important;
        }

        .e-tab-active {
            border-bottom: 0.125rem solid $egyptian_blue;
        }

        .e-arrow {
            display: none !important;
        }

        p {
            width: max-content !important;
        }
    }

    .e-tab-wrapper::-webkit-scrollbar {
        display: none !important;
    }

    .e-self-evaluation {
        justify-content: start !important;
    }

    // .e-filter-sort-wrapper {
    //     justify-content: end;
    // }

    .e-marketing-banner-title {
        font-size: 1.375rem !important;
        line-height: 1.75rem !important;
    }

    .e-market-banner-btn {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }

    .e-top-navigation-wrapper {
        h3 {
            font-size: 1rem !important;
        }
    }

    // .e-basket-card-title {
    //     p {
    //         padding-left: 0 !important;
    //     }
    // }

    .e-tab-item-wrapper {
        gap: 0 0.25rem !important;
    }

    .e-res-width-auto {
        width: auto !important;
    }


    .e-sort-modal-wrapper {
        left: -170px !important;
    }

    .e-filer-modal-wrapper {
        left: -220px !important;
        width: 28rem;
    }

    .padding-res-8px-bottom {
        padding-bottom: 0.5rem !important;
    }

    .e-padding-block-40px-res {
        padding-block: 2.5rem;
    }

    .e-padding-top-40px-res {
        padding-top: 2.5rem;
    }

    .w-welcome-modal-res {
        width: 500px !important;
    }

    .e-order-detail-heading {
        justify-content: start !important;
    }

    //Margin
    .margin-top-0px-res-md {
        margin-top: 0;
    }

    .position-relative-md {
        position: relative;
    }

    .h-93px-res-sm {
        height: 5.8rem;
    }

    .height-auto-res {
        height: auto !important;
    }

    .gradient-vr-line {
        &::after {
           display: none;
        }
    }
}

@media only screen and (max-width: 574px) {

    .e-home-wrapper {
        padding-right: 1rem !important;
    }

    .e-padding-right-res {
        padding-right: 1rem !important;
    }

    .e-marketing-banner-title {
        font-size: 1.125rem !important;
        line-height: 1.75rem !important;
    }

    .e-btn-custom-wrapper {
        font-size: 0.875rem !important;
    }


    .e-life-goal-card-content {
        // left: 6.875rem !important;
        // top: 1.375rem !important;
    }

    .e-goal-card-icon {
        left: 0.25rem !important;
        top: -0.75rem !important;
    }

    // .horizontal-rule {
    //     width: 5rem !important;
    // }

    .e-note-ipv {
        padding-top: 1rem !important;
    }

    .e-filer-modal-wrapper {
        width: auto !important;
        left: 0.25rem !important;
    }

    .e-sort-modal-wrapper {
        width: auto !important;
        left: 1rem !important;
    }

    .e-order-filter-modal {
        width: 18.5rem !important;
    }

    .e-marketing-banner-icon {
        img {
            width: 6.875rem;
            height: 6.25rem;
        }

        right: 0.5rem !important;
    }

    // .e-basket-card-title {
    //     p {
    //         font-size: 0.75rem !important;
    //         line-height: 0.875rem !important;
    //         padding-left: 0 !important;
    //     }
    // }

    .e-learn-banner-wrapper {
        h3 {
            font-size: 0.875rem !important;
            line-height: 0.875rem;
            /* 100% */
        }

        p {
            font-size: 0.75rem !important;
        }

    }

    .e-width-540px-res {
        width: 33.75rem !important;
    }

    .e-import-portfolio {
        font-size: 0.75rem !important;
        line-height: 1.125rem !important;
        padding-bottom: 0.625rem !important;
    }

    .e-invest-btn-goal-detail {
        padding: 0.25rem 1rem !important;
    }

    .e-invested-goal-title {
        font-size: 0.875rem !important;
        line-height: 1.125rem !important;
    }

    .e-evaluate-btn {
        padding: 0.5rem 0.625rem !important;
    }

    .e-pagination {
        gap: 0 0.5rem !important;
    }

    .e-pagination li {
        padding: 0 !important;
    }

    .e-breadcrumb-name {
        font-size: 0.75rem !important;
    }

    .e-graph-wrapper {
        width: 37.5rem !important;
    }

    .e-goal-step {
        font-size: 0.75rem !important;
    }

    .e-nominee-data {
        font-size: 1rem !important;
    }

    .e-kyc-email-des {
        font-size: 0.875rem !important;
        line-height: 1.5rem;
    }

    .e-portfolio-returns {
        font-size: 1rem;
    }

    .e-res-font-12 {
        font-size: 12px !important;
    }

    .e-padding-16px-tb-res {
        padding-block: 0.875rem !important;
    }

    .e-padding-16px-tb-res {
        padding-block: 0.875rem !important;
    }


    // Font //
    .e-font-12-res {
        font-size: 0.75rem !important;
    }

    .e-font-14-res {
        font-size: 0.875rem !important;
    }

    .e-font-16-res {
        font-size: 1rem !important;
    }

    .e-font-18-res {
         font-size: 1.125rem !important;
    }

    .e-font-20-res {
         font-size: 1.25rem !important;
    }

    .e-font-22-res {
        font-size: 1.375rem;
    }

    .e-padding-block-40px-res {
        padding-block: 2.5rem;
    }

    .padding-12px-right-res {
        padding-right: 0.75rem !important;
    }

    .w-welcome-modal-res {
        width: auto !important;
    }

    .margin-res-card-title {
        margin-left: -4px !important;
    }

    .margin-24px-bottom-res {
        margin-bottom: 1.5rem;
    }

    .margin-8px-bottom-res {
        margin-bottom: 0.5rem;
    }

    .margin-24px-top-res {
        margin-top: 1.5rem;
    }

    .no-margin-res {
        margin: 0 !important;
    }

    // Line height //
    .line-height-18px-res {
        line-height: 1.125rem !important;
    }
    .line-height-20px-res {
        line-height: 1.25rem !important;
    }

    .line-height-28px-res {
        line-height: 1.25rem !important;
    }

    .line-height-22px-res {
        line-height: 1.375rem !important;
    }

    .gap-0-res {
        gap: 0;
    }

    .gap-12-res {
        gap: 0.75rem;
    }

    // Padding //
    .e-padding-lr-res {
         padding-inline: 0rem !important;
    }

    .e-padding-24px-lr-res {
         padding-inline: 1.5rem !important;
    }

    .e-padding-30px-left-res {
        padding-left: 1.875rem !important;
    }

    .padding-14px-tb-res {
        padding-block: 0.875rem;
    }


    .padding-8px-tb-res {
        padding-block: 0.5rem !important;
    }

    .padding-6px-tb-res {
        padding-block: 0.375rem !important;
    }

    .padding-8px-all-res {
        padding: 0.5rem !important;
    }

    .padding-12px-all-res {
        padding: 0.75rem !important;
    }

    .padding-16px-all-res {
        padding: 1rem !important;
    }

    .padding-24px-all-res {
        padding: 1.5rem !important;
    }

    .padding-12px-lr-res {
        padding-inline: 0.75rem !important;
    }

    .padding-24px-top-res {
        padding-top: 1.5rem;
    }

    .padding-top-24px-res {
        padding-top: 1.5rem;
    }

    .padding-top-40px-res {
        padding-top: 2.5rem;
    }

    .padding-bottom-24px-res {
        padding-bottom: 1.5rem;
    }

    /* Width */

    .width-24px-res {
        width:1.5rem;
    }

    .width-32px-res {
        width:2rem;
    }

    .width-40px-res {
        width:2.5rem;
    }

    .width-20px-res {
        width:1.25rem;
    }

    .width-16px-res {
        width:1rem;
    }

    .w-50px-res {
    width: 3.125rem;
}

    /* Height */
    .height-20px-res {
        height:1.25rem;
    }

    .height-24px-res {
        height:1.5rem;
    }

    
    .height-32px-res {
        height:2rem;
    }

    .height-40px-res {
        height:2.5rem;
    }



    .h-42-res {
    height: 2.625rem;
}

    .top-6px-res {
        top: 0.375rem;
    }

    .bottom-14px-res {
        bottom: 0.875rem;
    }

    .top-50-res {
        top: 50%;
        transform: translateY(-50%);
    };

    .top-45-res {
        top: 45% !important;
    };


    .top-6px-res {
        top:0.375rem
    }

    .top-8px-res {
        top:0.5rem !important
    }

    .top-20px-res {
        top:1.25rem !important
    }

    .right-0-res {
        right: 0;
    }

    .left-70px-res {
        left: 4.375rem;
    }

    .padding-left-48px-res-important {
        padding-left: 3rem !important
    };

   .width-75per-res {
        width: 75% !important;
   }

    .h-104px-res-576px {
        height: 6.5rem;
    }

    ::placeholder {
        font-size: 14px !important;
    }

    .padding-32px-lr-res {
        padding-inline: 2rem !important;
    }

    // GAP
    .row-gap-16px-res {
        row-gap: 1rem;
    }

   .faq-tab {
    display: block;
        border-left: 0;
        &:hover {
            border-radius: 1rem;
            font-weight: 500 !important;
        }
    }

    .faq-tab.active-tab {
        border-left: 0;
        border-radius: 1rem;
    }

    #range-picker-inner-wrapper {
        width: 460px !important;
        overflow: scroll;
    }

    .text-nowrap-mobile-screen {
        // word-break: keep-all;
        white-space: nowrap;
   } 

   .margin-right-12px-res {
      margin-right: 0.75rem;
   }

    // phone input plugin
    .PhoneInput {
        padding-inline: 0rem;
        font-size: 0.875rem;
        line-height: 1.375rem;
      

    input {
            font-size: 0.875rem;
            line-height: 1.375rem;
            padding-block: 0.5rem;
            padding-right: 0.5rem;
            &::placeholder  {
                font-size: 0.875rem;
            }
        }

    }

    #search-phone-country {
        padding-left: 2rem !important;
    }

    .margin-10px-bottom-res {
        margin-bottom: 0.63rem;
    }

}



@media only screen and (min-width: 576px) and (max-width:768px) {
   #page-faq .container {
        max-width: 100% !important;
   }



}

@media only screen and (min-width: 440px) and (max-width:570px) {
//    .sip-date-picker {
//         width:80% !important;
//    }
}

@media only screen and (max-width: 440px) {
    .margin-res-card-title {
        margin-left: 16px !important;
    }

    .e-video-wrapper {
        width: 100% !important;
        height: 100% !important;
    }

    .e-avg-return {
        font-size: 0.625rem !important;
    }

    .e-avg-return-value {
        font-size: 0.75rem !important;
    }

    .e-chip-data {
        font-size: 0.75rem !important;
    }

    .e-res-w-max-content {
        width: max-content !important;
    }

    .w-40 {
        width: 60% !important;
    }

    .e-manage-bank {
        flex-direction: column !important;
        gap: 1rem !important;
    }

    .e-text-summary {
        font-size: 0.875rem;
    }

    .e-flex-column-res-400 {
        flex-direction: column;
    }

    .w-100-res {
        width: 100%;
    }

    .margin-left-32px-res-400 {
        margin-left: 2rem;
    }

    .h-80px-res-440px {
        height: 4.5rem;
    }

    #range-picker-inner-wrapper {
        width: 380px !important;
        overflow: scroll;
    }
}


@media only screen and (max-width: 380px) {
    .e-res-gap-0-8 {
        gap: 0 0.5rem;
    }

    .sip-date-picker {
        .d-grid-sip-date {
            gap:2px !important
        }
        .e-font-res-date {
            font-size: 0.75rem !important;
            margin-block: 0.25rem;
        }
    }

    #range-picker-inner-wrapper {
        width: 280px !important;
        overflow: scroll;
    }
}

/* ==============================
   Small Phone
   ==============================*/
@media only screen and (max-width: 319px) {}