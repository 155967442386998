@import "../global.scss";

//left navigation
.e_active_item {
    border-radius: 0px 0.75rem 0.75rem 0px;
    background: rgba(219, 232, 254, 0.50);

    p {
        color: $egyptian_blue !important;
        font-weight: 500 !important;
    }
}

.e_nav_item_hover {
    transition: all .2s ease-in-out;

    &:hover {
        border-radius: 0px 0.75rem 0.75rem 0px;
        background: rgba(219, 232, 254, 0.50);

        .e_arrow {
            transform: translateX(0.375rem);
        }
    }
}

//settings

.e_pushnotification {
    position: relative;
    display: inline-block;
    width: 3rem;
    height: 1.5rem;

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .e_slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0.125rem;
        bottom: 0;
        background: $lavender_gray;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 1.875rem;

        &:before {
            position: absolute;
            content: "";
            height: 1.25rem;
            width: 1.25rem;
            left: 0.125rem;
            top: 0.125rem;
            background: $white;
            box-shadow:
                0px 4px 12px 0px rgba(255, 255, 255, 0.25) inset,
                2px 4px 4px 0px rgba(255, 255, 255, 0.32) inset;
            -webkit-transition: 0.4s;
            transition: 0.4s;
            border-radius: 50%;
        }
    }

    input:checked+.e_slider {
        background-color: $egyptian_blue;
    }

    input:focus+.e_slider {
        box-shadow: 0 0 0.063rem transparent;
    }

    input:checked+.e_slider:before {
        -webkit-transform: translateX(1.375rem);
        -ms-transform: translateX(1.375rem);
        transform: translateX(1.375rem);
    }

}