@import "../color.scss";


.border_active {
    transition: all 0.3s ease;
    border: 1px solid $egyptian_blue;
    border-radius: 12px;
    box-shadow: 0px 0px 3px 0px #1C359F80;
}


.border_inactive {
    transition: all 0.3s ease;
    border: 1px solid #C4C6C8;
    border-radius: 12px;
    box-shadow: 0px 0px 1px 0px #00000033;
}
