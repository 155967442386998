@import "../global.scss";

.e_social_wrapper {
    img {
        transition: all .3s ease-in-out;

        &:hover {
            transform: scale(1.1);
        }
    }
}

.e_developed_by {
    &:hover {
        color: $egyptian_blue;
    }
}