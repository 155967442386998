@import "../global.scss";

.e_input {
    &:focus {
        outline: none !important;
    }

    &::placeholder {
        font-family: "Poppins" !important;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.25rem;
        color: $sonic_silver;
    }

}

input[type="number"]::-webkit-inner-spin-button {
    display: none;
}

.e_input_wrap input {
    border-radius: 0.75rem;
    border: 0.063rem solid $charleston-green;
    // padding: 0.625rem 0.75rem 0.625rem 3.75rem;
    // font-size: 0.875rem;

    &:focus {
        border: 0.063rem solid $egyptian_blue !important;
        outline: none !important;
    }

    &::placeholder {
        font-family: "Poppins" !important;
        font-size: 0.875rem;
        font-weight: 300 !important;
        line-height: 1.25rem;
        letter-spacing: 0.009rem;
    }
}

.e_input_phone_wrap input {
    border-radius: 0.75rem;
    border: 0.063rem solid $charleston-green;
    &:focus {
        outline: none !important;
    }
    &::placeholder {
        font-family: "Poppins" !important;
        font-size: 0.875rem;
        font-weight: 300 !important;
        line-height: 1.25rem;
        letter-spacing: 0.009rem;
    }
}

.text_area_placeholder {
    &::placeholder {
        font-family: "Poppins" !important;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.25rem;
        color: $sonic_silver;
    }
}

.e_error {
    border: 0.063rem solid $red !important;
}

.e_input_wrap input[type="number"]::-webkit-inner-spin-button {
    display: none;
}

.e_otp_wrapper {
    input::selection {
        color: transparent;
        background: transparent;
    }

    input {
        cursor: pointer;
        outline: none;
        border: none;
        color: transparent;
        position: absolute;
        height: 3.5rem;
        background: transparent;
    }

    label {
        div {
            border-radius: 0.75rem;
            background: transparent;
        }
    }
}


// amount input 
.e_amount_input_wrapper {

    input {
        &::placeholder {
            font-family: "Poppins" !important;
            font-size: 1rem;
            font-weight: 300;
            line-height: 1.25rem;
            letter-spacing: 0.009rem;
        }
    }
}