@import "../global.scss";

.e_links_wrapper {
    a {
        transition: all .2s ease-in-out;

        &:hover {
            color: $egyptian_blue;
        }
    }
}