@charset "UTF-8";

/*
----------------------------------
Table of contents

01. Google font
02. Reset
03. Typography
04. Background color
05. Custom
06. Border
07. Margin
08. Padding
09. Display and overflow
10. Position
11. Width
12. Height
13. Header
*/

/* ===================================
    01. Google font
====================================== */
@import "../Style/font.scss";
@import "../Style/responsive.scss";
@import "../Style/color.scss";
@import "../Style/global.scss";
@import "../Style/Component/table.module.scss";
@import "../Style/Component/calender.module.scss";

/* ===================================
    02. Reset
====================================== */

body {
    overflow-x: hidden;
    background-color: $alice_blue;
    // min-height: 100vh;
    // overflow: hidden;
}

body::-webkit-scrollbar {
    display: none !important;
}

.no-scrollbar::-webkit-scrollbar {
    display: none !important;
}

.e-body input[type="number"]::-webkit-inner-spin-button {
    display: none;
}

.e-body {
    // line-height: 30px !important;
    padding: 0;
    margin: 0;
    overflow-x: hidden !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    word-break: break-word;
    font-size: 1rem;
    // background-color: #ecedfd;

    ul,
    ol,
    dl {
        list-style-position: outside;
    }

    ul {
        margin: 0;
        padding: 0;
    }

    button,
    button:focus,
    button:hover {
        outline: none;
    }

    b,
    strong {
        font-weight: 600;
    }

}

.word-break {
    word-break: break-all;
}

.alt-font strong {
    font-weight: 600;
}

.user-select-none {
    user-select: none;
}

.resize-none {
    resize: none;
}



.no-transition {
    transition: all 0s ease-in-out !important;
    -moz-transition: all 0s ease-in-out !important;
    -webkit-transition: all 0s ease-in-out !important;
    -ms-transition: all 0s ease-in-out !important;
    -o-transition: all 0s ease-in-out !important;
}

.transition {
    transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

.transition-02s {
    transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
}



/* ===================================
    03. Typography
====================================== */

/* font family */
/* heading size */
.e-body {
    h2 {
        font-size: 1.5rem !important;
    }
}

/*-----------Font size ------------ */
.e-font-8 {
    font-size: 0.5rem !important;
}

.e-font-10 {
    font-size: 0.625rem !important;
}

.e-font-12 {
    font-size: 0.75rem;
}

.e-font-14 {
    font-size: 0.875rem;
}

.e-font-16 {
    font-size: 1rem;
}

.e-font-18 {
    font-size: 1.125rem;
}

.e-font-20 {
    font-size: 1.25rem !important;
}

.e-font-22 {
    font-size: 1.375rem;
}

.e-font-24 {
    font-size: 1.5rem;
}

.e-font-26 {
    font-size: 1.625rem;
}

.e-font-30 {
    font-size: 1.875rem;
}

.e-font-32 {
    font-size: 2rem;
}

.e-font-36 {
    font-size: 2.25rem;
}

.e-font-38 {
    font-size: 2.375rem;
}

.e-font-48 {
    font-size: 3rem;
}

.e-font-52 {
    font-size: 3.25rem;
}

.e-font-55 {
    font-size: 3.438rem;
}

// Font -style

/* text stroke */
.text-border {
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 0.063rem;
    -webkit-text-stroke-color: white;
}

/* text stroke width */
.text-border-width-1 {
    -webkit-text-stroke-width: 0.063rem;
}

.text-border-width-2 {
    -webkit-text-stroke-width: 0.125rem;
}

.text-border-width-3 {
    -webkit-text-stroke-width: 0.188rem;
}

.text-border-width-4 {
    -webkit-text-stroke-width: 0.25rem;
}

.text-border-width-5 {
    -webkit-text-stroke-width: 0.313rem;
}

/* text stroke color */
.text-border-color-black {
    -webkit-text-stroke-color: #000000;
}

/* Outline */
.no-outline {
    outline: none;
}

/* letter spacing */

.letter-spacing-44 {
    letter-spacing: 0.028rem;
}

.letter-spacing-3 {
    letter-spacing: 0.019rem;
}


.letter-spacing-4 {
    letter-spacing: 0.25rem;
}

.letter-spacing-5 {
    letter-spacing: 0.031rem;
}

.letter-spacing-15 {
    letter-spacing: 0.15px;
}

.letter-spacing-neg-2 {
    letter-spacing: -0.013rem;
}

.letter-spacing-neg-2per {
    letter-spacing: -0.32px;
}

// transform
.transform-scale-hover {
    transition: all 0.3s ease-in-out;

    &:hover {
        transform: scale(1.05);
        transition: all 0.3s ease-in-out;
    }
}

.translateY-4px {
    transform: translateY(0.2rem);
}

.hover-arrow-wrapper {
    &:hover {
        .hover-arrow {
            transform: translateX(0.375rem);
        }
    }

    .hover-arrow {
        transition: all 0.3s ease-in-out;
    }
}


/* text property */
.text-normal {
    text-transform: unset !important;
}

.text-decoration-underline {
    text-decoration: underline;
}

.text-decoration-none {
    text-decoration: none;
}

.link-hover:hover {
    color: inherit !important;
}

.link-white-hover:hover {
    color: $white !important;
}

.text-decoration-hover-underline:hover {

    text-decoration: underline !important;
}

.word-wrap {
    word-wrap: break-word;
}

.text-transform-none {
    text-transform: none;
}

.line-height-none {
    line-height: 0;
}

.text-decoration-line-through {
    text-decoration: line-through;
}

.transform-rotate-180 {
    transform: rotate(180deg);
}

.transfrom-rotate-270 {
    transform: rotate(270deg) !important;
}

/* ===================================
    05. Custom
====================================== */

.cursor-none {
    cursor: none;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-default {
    cursor: default;
}

.cursor-text {
    cursor: text;
}


.e-cursor-none {
    cursor: not-allowed !important;
}

.e-pointer-events-none {
    pointer-events: none;
}

/* opacity */
.opacity-0 {
    opacity: 0;
}

.opacity-1 {
    opacity: 0.1;
}

.opacity-2 {
    opacity: 0.2;
}

.opacity-3 {
    opacity: 0.3;
}

.opacity-4 {
    opacity: 0.4;
}

.opacity-5 {
    opacity: 0.5;
}

.opacity-6 {
    opacity: 0.6;
}

.opacity-7 {
    opacity: 0.7;
}

.opacity-8 {
    opacity: 0.8;
}

.opacity-9 {
    opacity: 0.9;
}

.opacity-10 {
    opacity: 1;
}


/* z-index */
.z-index-1 {
    z-index: 1;
}

.z-index-2 {
    z-index: 2;
}

.z-index-3 {
    z-index: 3;
}

.z-index-4 {
    z-index: 4;
}

.z-index-5 {
    z-index: 5;
}

.z-index-6 {
    z-index: 6;
}

.z-index-7 {
    z-index: 7;
}

.z-index-8 {
    z-index: 8;
}

.z-index-9 {
    z-index: 9;
}

.z-index-99 {
    z-index: 99;
}

.z-index-0 {
    z-index: 0;
}

.z-index-minus-1 {
    z-index: -1;
}

.z-index-999 {
    z-index: 999;
}

/* ===================================
    06. Border
====================================== */
.border-transparent {
    border: 0.063rem solid transparent;
}

.border-1-all {
    border: 0.063rem solid !important;
}

.border-top {
    border-top: 0.063rem solid !important;
}

.e-input-border {
    border: 0.063rem solid $charleston_green !important;
}

.e-border-bottom {
    border-bottom: 0.063rem solid $azureish_white;
}

.e-border-tab-active {
    border-bottom: 0.125rem solid $egyptian_blue;
}

.e-border-bottom-1px-egyptian-blue {
    border-bottom: 0.063rem solid $egyptian_blue;

}

.e-border-bottom-transparent {
    border-bottom: 0.063rem solid transparent;

}

.e-border-bottom-2px-bright-gray {
    border-bottom: 0.125rem solid $bright_gray ;
}

.e-border-bottom-1px-light-gray {
    border-bottom: 0.063rem solid $light_gray;
}

.e-border-bottom-1px-light-gray-opa-05 {
    border-bottom: 0.063rem solid $light_gray_opacity_05;
}

.e-border-bottom-1px-platinum-gray {
    border-bottom: 0.063rem solid $platinum_gray;
}


.e-border-otp {
    border: 0.063rem solid $sonic_silver;
}

.e-border-1px-sonic-silver {
    border: 0.063rem solid $sonic_silver;
}

.e-border-otp-active {
    border: 0.063rem solid $egyptian_blue;
}

.e-border-bright-gray {
    border: 0.063rem solid $bright_gray !important;
}

.e-border-1px-lavender-gray {
    border: 0.063rem solid $lavender_gray;
}

.e-border-1px-charleston-green {
    border: 0.063rem solid $charleston_green;
}

.e-border-1px-egyptian-blue {
    border: 0.063rem solid $egyptian_blue;
}

.e-border-1px-bright-gray {
    border: 0.063rem solid $bright_gray;
}
.e-border-1px-light-gray {
    border: 0.063rem solid $light_gray;
}

.e-border-1px-dashed-onyx {
    border: 0.125rem dashed $onyx;
}

/*  border-color */

.border-1px-all-bright_gray {
    border: 0.063rem solid $bright_gray !important;
}

.border-color-lavender {
    border-color: $lavender;
}

.border-color-egyptian-blue {
    border-color: $egyptian_blue;
}

.border-color-bright_gray {
    border-color: $bright_gray;
}

.border-color-light-gray {
    border-color: $light_gray;
}

.border-color-ufo-green {
    border: 0.063rem solid $ufo_green !important;
}

.border-red-1px {
    border: 0.063rem solid $red !important;
}

.border-width-2px {
    border-width: 0.126rem;
}

// .border-color-bright_gray {
//     border-color:$bright_gray;
// }

/* no border */
.no-border {
    border: 0 !important;
}

.no-border-top {
    border-top: 0 !important;
}

.no-border-bottom {
    border-bottom: 0 !important;
}

.no-border-right {
    border-right: 0 !important;
}

.no-border-left {
    border-left: 0 !important;
}


.border-left-4px-transparent {
    border-left: 0.25rem solid transparent;
}


.no-border-top-bottom {
    border-top: 0 !important;
    border-bottom: 0 !important;
}

.no-border-left-right {
    border-left: 0 !important;
    border-right: 0 !important;
}

/* border-radius */
.no-border-radius {
    border-radius: 0 !important;
}

.e-border-radius-4 {
    border-radius: 0.25rem !important;
}

.e-border-radius-6 {
    border-radius: 0.375rem !important;
}

.e-border-radius-8 {
    border-radius: 0.5rem !important;
}

.e-border-radius-10 {
    border-radius: 0.625rem !important;
}

.e-border-radius-12 {
    border-radius: 0.75rem !important;
}

.e-border-radius-16 {
    border-radius: 1rem !important;
}

.e-border-radius-24 {
    border-radius: 1.5rem !important;
}

.e-border-radius-32 {
    border-radius: 2rem !important;
}

.e-border-radius-42 {
    border-radius: 2.625rem !important;
}

.e-border-radius-48 {
    border-radius: 3rem !important;
}

.e-border-radius-56 {
    border-radius: 3.558rem !important;
}

.e-border-radius-100 {
    border-radius: 6.25rem !important;
}

.e-border-radius-50per {
    border-radius: 50%;
}

.e-border-radius-12px-0-0-12px {
    border-radius: 0.75rem 0 0 0.75rem
}

.e-border-radius-TL-BL-24px {
    border-radius: 1.5rem 0 0 1.5rem;
}

/* ================================
            Line height
=================================== */
.line-height-0 {
    line-height: 0rem;
}

.line-height-16 {
    line-height: 1rem;
}

.line-height-24 {
    line-height: 1.5rem;
}

.line-height-0px {
    line-height: 0;
}

.line-height-8px {
    line-height: 0.5rem;
}

.line-height-10px {
    line-height: 0.625rem;
}

.line-height-12px {
    line-height: 0.75rem;
}

.line-height-14px {
    line-height: 0.875rem !important;
}

.line-height-15px {
    line-height: 0.9375rem;
}

.line-height-16px {
    line-height: 1rem !important;
}

.line-height-17px {
    line-height: 1.0625rem !important;
}

.line-height-18px {
    line-height: 1.125rem;
}

.line-height-20px {
    line-height: 1.25rem !important;
}

.line-height-22px {
    line-height: 1.375rem;
}

.line-height-24px {
    line-height: 1.5rem;
}

.line-height-26px {
    line-height: 1.625rem;
}

.line-height-28px {
    line-height: 1.75rem;
}

.line-height-30px {
    line-height: 1.875rem;
}

.line-height-32px {
    line-height: 2rem;
}

.line-height-34px {
    line-height: 2.125rem;
}

.line-height-36px {
    line-height: 2.25rem;
}

.line-height-38px {
    line-height: 2.375rem;
}

.line-height-40px {
    line-height: 2.5rem;
}

.line-height-42px {
    line-height: 2.625rem;
}

.line-height-44px {
    line-height: 2.75rem;
}

.line-height-46px {
    line-height: 2.875rem;
}

.line-height-48px {
    line-height: 3rem;
}


.line-height-50px {
    line-height: 3.125rem;
}

.line-height-55px {
    line-height: 3.4375rem;
}

.line-height-60px {
    line-height: 3.75rem;
}

.line-height-62px {
    line-height: 3.875rem;
}

.line-height-65px {
    line-height: 4.0625rem;
}

.line-height-70px {
    line-height: 4.375rem;
}

.line-height-75px {
    line-height: 4.6875rem;
}

.line-height-80px {
    line-height: 5rem;
}

.line-height-85px {
    line-height: 5.3125rem;
}

.line-height-90px {
    line-height: 5.625rem;
}

.line-height-95px {
    line-height: 5.9375rem;
}

.line-height-100px {
    line-height: 6.25rem;
}

.line-height-120px {
    line-height: 7.5rem;
}

.line-height-140px {
    line-height: 8.75rem;
}

.line-height-160px {
    line-height: 10rem;
}

.line-height-170px {
    line-height: 10.625rem;
}

.e-line-height-100 {
    line-height: 100%;
}

.line-height-120 {
    line-height: 7.5rem;
}

.line-height-140 {
    line-height: 8.75rem;
}

.line-height-160 {
    line-height: 10rem;
}

.line-height-177 {
    line-height: 11.11875rem;
}

.line-height-180 {
    line-height: 11.25rem;
}

.e-line-height-120 {
    line-height: 7.5rem;
}

.e-line-height-140 {
    line-height: 8.75rem;
}

.-per {
    line-height: 9.375rem;
}

.e-line-height-160 {
    line-height: 10rem;
}

.e-line-height-161 {
    line-height: 161.523%;
}

.e-line-height-normal {
    line-height: normal;
}

.e-line-height-180 {
    line-height: 11.25rem;
}

.e-line-height-26 {
    line-height: 1.625rem;
}

.e-line-height-150-per {
    line-height: 150%;
}

/* ===================================
    07. Margin
====================================== */

.margin-5-all {
    margin: 0.313rem;
}

.margin-negative-15px-left {
    margin-left: -0.938rem;
}

.margin-negative-12px-left {
    margin-left: -0.75rem;
}

.margin-left-16-point-5per {
    margin-left: 16.5%;
}

/* margin bottom */

.margin-2px-bottom {
    margin-bottom: 0.125rem;
}

.margin-3px-bottom {
    margin-bottom: 0.188rem;
}

.margin-5-bottom {
    margin-bottom: 0.313rem;
}

.margin-6px-bottom {
    margin-bottom: 0.375rem;
}

.margin-7px-bottom {
    margin-bottom: 0.438rem;
}

.margin-10px-bottom {
    margin-bottom: 0.625rem;
}

.margin-12px-bottom {
    margin-bottom: 0.75rem;
}

.margin-19px-bottom {
    margin-bottom: 1.188rem;
}

.margin-30px-bottom {
    margin-bottom: 1.875rem;
}

.margin-32px-bottom {
    margin-bottom: 2rem;
}

.margin-34px-bottom {
    margin-bottom: 2.125rem;
}

.margin-36px-bottom {
    margin-bottom: 2.25rem;
}

.margin-16-bottom {
    margin-bottom: 1rem;
}

.margin-43px-bottom {
    margin-bottom: 2.675rem;
}

.margin-40px-bottom {
    margin-bottom: 2.5rem;
}

.margin-68px-bottom {
    margin-bottom: 4.25rem;
}

.margin-70px-bottom {
    margin-bottom:4.375rem;
}

/* margin right */

.margin-5-right {
    margin-right: 0.313rem;
}

.margin-6-right {
    margin-right: 0.375rem;
}

.margin-10px-right {
    margin-right: 0.75rem;
}

.margin-12px-right {
    margin-right: 0.75rem;
}

.margin-16px-right {
    margin-right: 16px !important;
}

/* margin left */

.margin-5-left {
    margin-left: 0.313rem;
}

.margin-10-left {
    margin-left: 0.625rem;
}

.margin-12px-left {
    margin-left: 0.75rem;
}

.margin-32px-left {
    margin-left: 32rem;
}

.margin-40px-left {
    margin-left: 2.5rem;
}

.margin-28px-neg-left {
    margin-left: -1.75rem;
}

/* margin left right */

.margin-5-lr {
    margin-left: 0.313rem;
    margin-right: 0.313rem;
}

.margin-auto-lr {
    margin: 0 auto;
}

.margin-auto {
    margin: auto;
}

.margin-auto-0px {
    margin: auto 0;
}

.no-margin {
    margin: 0 !important;
}

// margin top
.no-margin-top {
    margin-top: 0 !important;
}

.margin-8px-neg-top {
    margin-top: -0.5rem;
}

.margin-10-top {
    margin-top: 0.625rem;
}

.margin-12px-top {
    margin-top: 0.75rem;
}

.margin-14-top {
    margin-top: 0.875rem;
}

.margin-top-18px {
    margin-top: 1.125rem;
}

.margin-top-22px {
    margin-top: 1.375rem;
}

.margin-top-28px {
    margin-top: 1.75rem;
}

.margin-top-neg-24px {
    margin-top: -1.5rem;
}


.margin-top-32px {
    margin-top: 2rem;
}

.margin-top-34px {
    margin-top: 2.125rem;
}

.margin-top-40px {
    margin-top: 2.5rem;
}

.margin-top-48px {
    margin-top: 3rem;
}

.margin-top-neg-48px {
    margin-top: -3rem;
}

.margin-top-neg-56px {
    margin-top: -3.5rem;
}

.margin-top-140px {
    margin-top: 8.75rem;
}

.margin-top-174px {
    margin-top: 10.875rem;
}

.margin-220px-top {
    margin-top: 13.75rem;
}

//negative margin top
.margin-neg-18px-top {
    margin-top: -1.125rem;
}

.margin-neg-56px-top {
    margin-top: -3.5rem;
}

.no-margin-bottom {
    margin-bottom: 0 !important;
}

.no-margin-left {
    margin-left: 0 !important;
}

.no-margin-right {
    margin-right: 0 !important;
}

.no-margin-lr {
    margin-right: 0 !important;
    margin-left: 0 !important;
}

/* margin block  top and bottom */
.margin-block-end-0 {
    margin-block-end: 0 !important;
}

.margin-block-start-0 {
    margin-block-start: 0 !important;
}

.margin-block-32px {
    margin-block: 2rem;
}

/* ===================================
    08. Padding
====================================== */

.padding-1px-all {
    padding: 0.063rem;
}

.padding-2px-all {
    padding: 0.125rem;
}

.padding-16-all {
    padding: 1rem;
    /* 16px / 16px */
}

.padding-5px-all {
    padding: 0.3125rem;
    /* 5px / 16px */
}

.padding-8px-all {
    padding: 0.5rem;
    /* 8px / 16px */
}

.padding-10px-all {
    padding: 0.625rem;
    /* 10px / 16px */
}

.padding-12px-all {
    padding: 0.75rem;
    /* 12px / 16px */
}

.padding-15px-all {
    padding: 0.9375rem;
    /* 15px / 16px */
}

.padding-16px-all {
    padding: 1rem;
    /* 16px / 16px */
}

.padding-20px-all {
    padding: 1.25rem;
    /* 20px / 16px */
}

.padding-21px-all {
    padding: 1.3125rem;
    /* 21px / 16px */
}

.padding-22px-all {
    padding: 1.375rem;
}

.padding-24px-all {
    padding: 1.5rem;
    /* 24px / 16px */
}

.padding-25px-all {
    padding: 1.5625rem;
    /* 25px / 16px */
}

.padding-30px-all {
    padding: 1.875rem;
    /* 30px / 16px */
}

.padding-32px-all {
    padding: 2rem;
    /* 32px / 16px */
}

.padding-35px-all {
    padding: 2.1875rem;
    /* 35px / 16px */
}

.padding-40px-all {
    padding: 2.5rem;
    /* 40px / 16px */
}

.padding-45px-all {
    padding: 2.8125rem;
    /* 45px / 16px */
}

.padding-50px-all {
    padding: 3.125rem;
    /* 50px / 16px */
}

.padding-55px-all {
    padding: 3.4375rem;
    /* 55px / 16px */
}

.padding-60px-all {
    padding: 3.75rem;
    /* 60px / 16px */
}

.padding-65px-all {
    padding: 4.0625rem;
    /* 65px / 16px */
}

.padding-70px-all {
    padding: 4.375rem;
    /* 70px / 16px */
}

.padding-75px-all {
    padding: 4.6875rem;
    /* 75px / 16px */
}

.padding-80px-all {
    padding: 5rem;
    /* 80px / 16px */
}

.padding-85px-all {
    padding: 5.3125rem;
    /* 85px / 16px */
}

.padding-90px-all {
    padding: 5.625rem;
    /* 90px / 16px */
}

.padding-95px-all {
    padding: 5.9375rem;
    /* 95px / 16px */
}

.padding-100px-all {
    padding: 6.25rem;
    /* 100px / 16px */
}

.padding-34-12px {
    padding: 0.75rem 2.125rem !important;
}

.padding-2px-8px {
    padding: 0.125rem 0.5rem;
}

.e-number-input-padding {
    padding: 0.875rem 0.75rem 0.875rem 3.75rem !important;
}

.e-text-input-padding {
    padding: 0.875rem 0.75rem;
}

/* padding top */

.padding-2-top {
    padding-top: 0.125rem;
}

.padding-5-top {
    padding-top: 0.313rem;
}

.padding-6-top {
    padding-top: 0.375rem;
}

.padding-7px-top {
    padding-top: 0.4375rem;
    /* 7px / 16px */
}

.padding-8px-top {
    padding-top: 0.5rem;
    /* 8px / 16px */
}

.padding-10px-top {
    padding-top: 0.625rem;
    /* 10px / 16px */
}

.padding-12px-top {
    padding-top: 0.75rem;
    /* 10px / 16px */
}

.padding-14px-top {
    padding-top: 0.875rem;
    /* 15px / 16px */
}

.padding-16-top {
    padding-top: 1rem;
}

.padding-18px-top {
    padding-top: 1.125rem;
}

.padding-20px-top {
    padding-top: 1.25rem;
    /* 20px / 16px */
}

.padding-22px-top {
    padding-top: 1.375rem;
    /* 20px / 16px */
}

.padding-24-top {
    padding-top: 1.5rem;
}

.padding-25px-top {
    padding-top: 1.5625rem;
    /* 25px / 16px */
}

.padding-26px-top {
    padding-top: 1.625rem !important;
    /* 25px / 16px */
}

.padding-28px-top {
    padding-top: 1.75rem;
    /* 28px / 16px */
}

.padding-30px-top {
    padding-top: 1.875rem;
    /* 30px / 16px */
}

.padding-32px-top {
    padding-top: 2rem;
}

.padding-34px-top {
    padding-top: 2.125rem;
    /* 35px / 16px */
}

.padding-36px-top {
    padding-top: 2.25rem;
}

.padding-38px-top {
    padding-top: 2.375rem;
}

.padding-40px-top {
    padding-top: 2.5rem;
    /* 40px / 16px */
}

.padding-42px-top {
    padding-top: 2.625rem;
}

.padding-44px-top {
    padding-top: 2.75rem;
}

.padding-45px-top {
    padding-top: 2.8125rem;
    /* 45px / 16px */
}

.padding-48px-top {
    padding-top: 3rem;
}

.padding-50px-top {
    padding-top: 3.125rem;
    /* 50px / 16px */
}

.padding-52px-top {
    padding-top: 3.25rem;
}


.padding-55px-top {
    padding-top: 3.4375rem;
    /* 55px / 16px */
}

.padding-54px-top {
    padding-top: 3.375rem;
    /* 55px / 16px */
}

.padding-56px-top {
    padding-top: 3.5rem;
}

.padding-58px-top {
    padding-top: 3.625rem;
    /* 55px / 16px */
}

.padding-60px-top {
    padding-top: 3.75rem;
    /* 60px / 16px */
}

.padding-65px-top {
    padding-top: 4.0625rem;
    /* 65px / 16px */
}

.padding-70px-top {
    padding-top: 4.375rem;
    /* 70px / 16px */
}

.padding-75px-top {
    padding-top: 4.6875rem;
    /* 75px / 16px */
}

.padding-76px-top {
    padding-top: 4.75rem;
    /* 80px / 16px */
}

.padding-80px-top {
    padding-top: 5rem;
    /* 80px / 16px */
}


.padding-82px-top {
    padding-top: 5.125rem;
    /* 80px / 16px */
}

.padding-84px-top {
    padding-top: 5.25rem;
    /* 80px / 16px */
}

.padding-85px-top {
    padding-top: 5.3125rem;
    /* 85px / 16px */
}

.padding-86px-top {
    padding-top: 5.375rem;
}

.padding-88px-top {
    padding-top: 5.5rem;
}


.padding-90px-top {
    padding-top: 5.625rem;
    /* 90px / 16px */
}

.padding-95px-top {
    padding-top: 5.9375rem;
    /* 95px / 16px */
}

.padding-98px-top {
    padding-top: 6.125rem;
}

.padding-100px-top {
    padding-top: 6.25rem;
    /* 100px / 16px */
}

.padding-132px-top {
    padding-top: 8.25rem;
}

.padding-150px-top {
    padding-top: 9.375rem;
    /* 100px / 16px */
}

.padding-160px-top {
    padding-top: 160px;
}

.padding-180px-top {
    padding-top: 11.25rem;
}

.padding-186px-top {
    padding-top: 11.625rem;
}

.padding-220px-top {
    padding-top: 13.75rem;
}

/* padding bottom */
.padding-2px-bottom {
    padding-bottom: 0.125rem;
    /* 5px / 16px */
}
.padding-5px-bottom {
    padding-bottom: 0.3125rem;
    /* 5px / 16px */
}

.padding-6px-bottom {
    padding-bottom: 0.375rem;
}

.padding-8px-bottom {
    padding-bottom: 0.5rem;
    /* 8px / 16px */
}

.padding-10px-bottom {
    padding-bottom: 0.625rem;
    /* 10px / 16px */
}

.padding-12px-bottom {
    padding-bottom: 0.75rem;
    /* 10px / 16px */
}

.padding-14px-bottom {
    padding-bottom: 0.875rem;
    /* 15px / 16px */
}

.padding-16-bottom {
    padding-bottom: 1rem;
}

.padding-15px-bottom {
    padding-bottom: 0.9375rem;
    /* 15px / 16px */
}

.padding-18px-bottom {
    padding-bottom: 1.125rem;
    /* 15px / 16px */
}

.padding-16-bottom {
    padding-bottom: 1rem;
}

.padding-20px-bottom {
    padding-bottom: 1.25rem;
    /* 20px / 16px */
}

.padding-22px-bottom {
    padding-bottom: 1.375rem;
    /* 20px / 16px */
}

.padding-24px-bottom {
    padding-bottom: 1.5rem;
    /* 25px / 16px */
}

.padding-25px-bottom {
    padding-bottom: 1.5625rem;
    /* 25px / 16px */
}

.padding-28px-bottom {
    padding-bottom: 1.75rem;
}

.padding-30px-bottom {
    padding-bottom: 1.875rem;
    /* 30px / 16px */
}

.padding-32px-bottom {
    padding-bottom: 2rem;
    /* 30px / 16px */
}

.padding-35px-bottom {
    padding-bottom: 2.1875rem;
    /* 35px / 16px */
}

.padding-36px-bottom {
    padding-bottom: 2.25rem;
}

.padding-40px-bottom {
    padding-bottom: 2.5rem;
    /* 40px / 16px */
}

.padding-44px-bottom {
    padding-bottom: 2.75rem;
}

.padding-50px-bottom {
    padding-bottom: 3.125rem;
    /* 50px / 16px */
}

.padding-54px-bottom {
    padding-bottom: 3.375rem;
    /* 55px / 16px */
}

.padding-58px-bottom {
    padding-bottom: 3.625rem;
    /* 58px / 16px */
}

.padding-60px-bottom {
    padding-bottom: 3.75rem;
    /* 60px / 16px */
}

.padding-64px-bottom {
    padding-bottom: 4rem;
}

.padding-65px-bottom {
    padding-bottom: 4.0625rem;
    /* 65px / 16px */
}

.padding-68px-bottom {
    padding-bottom: 4.25rem;
}

.padding-70px-bottom {
    padding-bottom: 4.375rem;
    /* 70px / 16px */
}

.padding-75px-bottom {
    padding-bottom: 4.6875rem;
    /* 75px / 16px */
}

.padding-76px-bottom {
    padding-bottom: 4.75rem;
}

.padding-80px-bottom {
    padding-bottom: 5rem;
    /* 80px / 16px */
}

.padding-85px-bottom {
    padding-bottom: 5.3125rem;
    /* 85px / 16px */
}

.padding-90px-bottom {
    padding-bottom: 5.625rem;
    /* 90px / 16px */
}

.padding-92px-bottom {
    padding-bottom: 5.75rem;
    /* 90px / 16px */
}

.padding-95px-bottom {
    padding-bottom: 5.9375rem;
    /* 95px / 16px */
}

.padding-96px-bottom {
    padding-bottom: 6rem;
    /* 95px / 16px */
}

.padding-100px-bottom {
    padding-bottom: 6.25rem;
    /* 100px / 16px */
}

.padding-106px-bottom {
    padding-bottom: 6.625rem;
    /* 100px / 16px */
}

.padding-140px-bottom {
    padding-bottom: 8.75rem;
    /* 100px / 16px */
}

.padding-67-bottom {
    padding-bottom: 4.1875rem;
    /* 67% / 16px */
}

.padding-100px-bottom {
    padding-bottom: 6.25rem;
}

.padding-110px-bottom {
    padding-bottom: 6.875rem;
}

.padding-120px-bottom {
    padding-bottom: 7.5rem;
}

.padding-140px-bottom {
    padding-bottom: 8.75rem;
}

.padding-150px-bottom {
    padding-bottom: 9.375rem;
}

.padding-160px-bottom {
    padding-bottom: 10rem;
    /* 160px / 16px */
}

.padding-162px-bottom {
    padding-bottom: 10.125rem;
}

.padding-174px-bottom {
    padding-bottom: 10.875rem;
}

.padding-180px-bottom {
    padding-bottom: 11.25rem;
    /* 180px / 16px */
}

.padding-186px-bottom {
    padding-bottom: 11.625rem;
}

.padding-190px-bottom {
    padding-bottom: 11.875rem;
}

.padding-100-bottom {
    padding-bottom: 6.25rem;
    /* 100% / 16px */
}

.padding-174-bottom {
    padding-bottom: 10.875rem;
}

.padding-210-bottom {
    padding-bottom: 13.125rem;
}

.padding-220-bottom {
    padding-bottom: 13.75rem;
}

.padding-230-bottom {
    padding-bottom: 14.375rem;
}

.padding-246-bottom {
    padding-bottom: 15.375rem;
}

.padding-266-bottom {
    padding-bottom: 16.625rem;
}

.padding-302-bottom {
    padding-bottom: 18.875rem;
}

.padding-332-bottom {
    padding-bottom: 20.75rem;
}

/* padding left */

.padding-5-left {
    padding-left: 0.313rem;
}

.padding-10px-left {
    padding-left: 0.625rem;
    /* 10px / 16px */
}

.padding-12px-left {
    padding-left: 0.75rem;
}

.padding-15px-left {
    padding-left: 0.9375rem;
    /* 15px / 16px */
}

.padding-16-left {
    padding-left: 1rem;
}

.padding-20px-left {
    padding-left: 1.25rem;
    /* 20px / 16px */
}

.padding-24px-left {
    padding-left: 1.5rem;
}

.padding-25px-left {
    padding-left: 1.5625rem;
    /* 25px / 16px */
}

.padding-28px-left {
    padding-left: 1.75rem;
    /* 25px / 16px */
}

.padding-30px-left {
    padding-left: 1.875rem;
    /* 30px / 16px */
}

.padding-32px-left {
    padding-left: 2rem;
    /* 32px / 16px */
}

.padding-34px-left {
    padding-left: 2.125rem;
}

.padding-35px-left {
    padding-left: 2.1875rem;
    /* 35px / 16px */
}

.padding-36px-left {
    padding-left: 2.25rem;
    /* 36px / 16px */
}

.padding-40px-left {
    padding-left: 2.5rem;
    /* 40px / 16px */
}

.padding-42-left {
    padding-left: 2.625rem;
}

.padding-46px-left {
    padding-left: 2.875rem;
}

.padding-48px-left {
    padding-left: 3rem;
    /* 45px / 16px */
}

.padding-50px-left {
    padding-left: 3.125rem;
    /* 50px / 16px */
}

.padding-55px-left {
    padding-left: 3.4375rem !important;
    /* 55px / 16px */
}

.padding-56px-left {
    padding-left: 3.5rem !important;
    /* 56px / 16px */
}

.padding-60px-left {
    padding-left: 3.75rem;
    /* 60px / 16px */
}

.padding-65px-left {
    padding-left: 4.0625rem;
    /* 65px / 16px */
}

.padding-70px-left {
    padding-left: 4.375rem;
    /* 70px / 16px */
}

.padding-75px-left {
    padding-left: 4.6875rem;
    /* 75px / 16px */
}

.padding-80px-left {
    padding-left: 5rem;
    /* 80px / 16px */
}

.padding-85px-left {
    padding-left: 5.3125rem;
    /* 85px / 16px */
}

.padding-88px-left {
    padding-left: 5.5rem;
    /* 88px / 16px */
}

.padding-90px-left {
    padding-left: 5.625rem;
    /* 90px / 16px */
}

.padding-95px-left {
    padding-left: 5.9375rem;
    /* 95px / 16px */
}

.padding-100px-left {
    padding-left: 6.25rem;
    /* 100px / 16px */
}

.padding-116px-left {
    padding-left: 7.25rem;
    /* 100px / 16px */
}

/* padding right */
.padding-0-right {
    padding-right: 0;
}

.padding-4-right {
    padding-right: 0.25rem;
}

.padding-5-right {
    padding-right: 0.313rem;
}

.padding-10px-right {
    padding-right: 0.625rem;
    /* 10px / 16px */
}

.padding-12-right {
    padding-right: 0.75rem;
}

.padding-15px-right {
    padding-right: 0.9375rem;
    /* 15px / 16px */
}

.padding-16-right {
    padding-right: 1rem !important;
}

.padding-20px-right {
    padding-right: 1.25rem;
    /* 20px / 16px */
}

.padding-24px-right {
    padding-right: 1.5rem;
    /* 24px / 16px */
}

.padding-25px-right {
    padding-right: 1.5625rem;
    /* 25px / 16px */
}

.padding-30px-right {
    padding-right: 1.875rem;
    /* 30px / 16px */
}

.padding-32px-right {
    padding-right: 2rem;
    /* 30px / 16px */
}

.padding-35px-right {
    padding-right: 2.1875rem;
    /* 35px / 16px */
}

.padding-40px-right {
    padding-right: 2.5rem;
    /* 40px / 16px */
}

.padding-42-right {
    padding-right: 2.625rem;
}

.padding-46px-right {
    padding-right: 2.875rem;
}

.padding-48px-right {
    padding-right: 3rem;
}

.padding-50px-right {
    padding-right: 3.125rem;
    /* 50px / 16px */
}

.padding-55px-right {
    padding-right: 3.4375rem;
    /* 55px / 16px */
}

.padding-60px-right {
    padding-right: 3.75rem;
    /* 60px / 16px */
}

.padding-65px-right {
    padding-right: 4.0625rem;
    /* 65px / 16px */
}

.padding-70px-right {
    padding-right: 4.375rem;
    /* 70px / 16px */
}

.padding-75px-right {
    padding-right: 4.6875rem;
    /* 75px / 16px */
}

.padding-80px-right {
    padding-right: 5rem;
    /* 80px / 16px */
}

.padding-85px-right {
    padding-right: 5.3125rem;
    /* 85px / 16px */
}

.padding-90px-right {
    padding-right: 5.625rem;
    /* 90px / 16px */
}

.padding-94px-right {
    padding-right: 5.875rem;
    /* 95px / 16px */
}

.padding-95px-right {
    padding-right: 5.9375rem;
    /* 95px / 16px */
}

.padding-100px-right {
    padding-right: 6.25rem;
    /* 100px / 16px */
}

.padding-132px-right {
    padding-right: 8.25rem;
}


/* padding top bottom */

.padding-2px-tb {
    padding-top: 0.125rem;
    /* 2px / 16px */
    padding-bottom: 0.125rem;
    /* 2px / 16px */
}

.padding-4-tb {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

.padding-5-tb {
    padding-top: 0.313rem;
    padding-bottom: 0.313rem;
}

.padding-6-tb {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
}

.padding-10px-tb {
    padding-block: 0.625rem !important;
}

.padding-11px-tb {
    padding-block: 11px !important;
}

.padding-tb-date {
    padding-block: 0.68rem;
}

.padding-11px-tb {
    padding-top: 0.6875rem;
    /* 11px / 16px */
    padding-bottom: 0.6875rem;
    /* 11px / 16px */
}

.padding-12px-tb {
    padding-block: 0.75rem;
}

.padding-14px-tb {
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
}

.padding-15px-tb {
    padding-top: 0.9375rem;
    /* 15px / 16px */
    padding-bottom: 0.9375rem;
    /* 15px / 16px */
}

.padding-20px-tb {
    padding-top: 1.25rem;
    /* 20px / 16px */
    padding-bottom: 1.25rem;
    /* 20px / 16px */
}

.padding-24px-tb {
    padding-top: 1.5rem;
    /* 24px / 16px */
    padding-bottom: 1.5rem;
    /* 24px / 16px */
}

.padding-25px-tb {
    padding-top: 1.5625rem;
    /* 25px / 16px */
    padding-bottom: 1.5625rem;
    /* 25px / 16px */
}

.padding-30px-tb {
    padding-top: 1.875rem;
    /* 30px / 16px */
    padding-bottom: 1.875rem;
    /* 30px / 16px */
}

.padding-32px-tb {
    padding-top: 2rem;
    /* 32px / 16px */
    padding-bottom: 2rem;
    /* 32px / 16px */
}

.padding-35px-tb {
    padding-top: 2.1875rem;
    /* 35px / 16px */
    padding-bottom: 2.1875rem;
    /* 35px / 16px */
}

.padding-40px-tb {
    padding-top: 2.5rem;
    /* 40px / 16px */
    padding-bottom: 2.5rem;
    /* 40px / 16px */
}

.padding-45px-tb {
    padding-top: 2.8125rem;
    /* 45px / 16px */
    padding-bottom: 2.8125rem;
    /* 45px / 16px */
}

.padding-50px-tb {
    padding-top: 3.125rem;
    /* 50px / 16px */
    padding-bottom: 3.125rem;
    /* 50px / 16px */
}

.padding-55px-tb {
    padding-top: 3.4375rem;
    /* 55px / 16px */
    padding-bottom: 3.4375rem;
    /* 55px / 16px */
}

.padding-60px-tb {
    padding-top: 3.75rem;
    /* 60px / 16px */
    padding-bottom: 3.75rem;
    /* 60px / 16px */
}

.padding-65px-tb {
    padding-top: 4.0625rem;
    /* 65px / 16px */
    padding-bottom: 4.0625rem;
    /* 65px / 16px */
}

.padding-70px-tb {
    padding-top: 4.375rem;
    /* 70px / 16px */
    padding-bottom: 4.375rem;
    /* 70px / 16px */
}

.padding-75px-tb {
    padding-top: 4.6875rem;
    /* 75px / 16px */
    padding-bottom: 4.6875rem;
    /* 75px / 16px */
}

.padding-80px-tb {
    padding-top: 5rem;
    /* 80px / 16px */
    padding-bottom: 5rem;
    /* 80px / 16px */
}

.padding-85px-tb {
    padding-top: 5.3125rem;
    /* 85px / 16px */
    padding-bottom: 5.3125rem;
    /* 85px / 16px */
}

.padding-90px-tb {
    padding-top: 5.625rem;
    /* 90px / 16px */
    padding-bottom: 5.625rem;
    /* 90px / 16px */
}

.padding-95px-tb {
    padding-top: 5.9375rem;
    /* 95px / 16px */
    padding-bottom: 5.9375rem;
    /* 95px / 16px */
}

.padding-100px-tb {
    padding-top: 6.25rem;
    /* 100px / 16px */
    padding-bottom: 6.25rem;
    /* 100px / 16px */
}

/* padding left right */
.padding-5-lr {
    padding-left: 0.313rem;
    padding-right: 0.313rem;
}

.padding-6-lr {
    padding-left: 0.375rem;
    padding-right: 0.375rem;
}

.padding-8px-lr {
    padding-left: 0.5rem;
    /* 8px / 16px */
    padding-right: 0.5rem;
    /* 8px / 16px */
}

.padding-10px-lr {
    padding-left: 0.625rem;
    /* 10px / 16px */
    padding-right: 0.625rem;
    /* 10px / 16px */
}

.padding-15px-lr {
    padding-left: 0.9375rem;
    /* 15px / 16px */
    padding-right: 0.9375rem;
    /* 15px / 16px */
}

.padding-16px-lr {
    padding-left: 1rem;
    /* 15px / 16px */
    padding-right: 1rem;
    /* 15px / 16px */
}

.padding-20px-lr {
    padding-left: 1.25rem;
    /* 20px / 16px */
    padding-right: 1.25rem;
    /* 20px / 16px */
}

.padding-25px-lr {
    padding-left: 1.5625rem;
    /* 25px / 16px */
    padding-right: 1.5625rem;
    /* 25px / 16px */
}

.padding-30px-lr {
    padding-left: 1.875rem;
    /* 30px / 16px */
    padding-right: 1.875rem;
    /* 30px / 16px */
}

.padding-32px-lr {
    padding-left: 2rem;
    /* 32px / 16px */
    padding-right: 2rem;
    /* 32px / 16px */
}

.padding-35px-lr {
    padding-left: 2.1875rem;
    /* 35px / 16px */
    padding-right: 2.1875rem;
    /* 35px / 16px */
}

.padding-40px-lr {
    padding-left: 2.5rem;
    /* 40px / 16px */
    padding-right: 2.5rem;
    /* 40px / 16px */
}

.padding-42px-lr {
    padding-left: 2.625rem;
    /* 42px / 16px */
    padding-right: 2.625rem;
    /* 42px / 16px */
}

.padding-45px-lr {
    padding-left: 2.8125rem;
    /* 45px / 16px */
    padding-right: 2.8125rem;
    /* 45px / 16px */
}

.padding-50px-lr {
    padding-left: 3.125rem !important;
    /* 50px / 16px */
    padding-right: 3.125rem !important;
    /* 50px / 16px */
}

.padding-55px-lr {
    padding-left: 3.4375rem;
    /* 55px / 16px */
    padding-right: 3.4375rem;
    /* 55px / 16px */
}

.padding-56px-lr {
    padding-left: 3.5rem !important;
    /* 55px / 16px */
    padding-right: 3.5rem !important;
    /* 55px / 16px */
}

.padding-60px-lr {
    padding-left: 3.75rem;
    /* 60px / 16px */
    padding-right: 3.75rem;
    /* 60px / 16px */
}

.padding-65px-lr {
    padding-left: 4.0625rem;
    /* 65px / 16px */
    padding-right: 4.0625rem;
    /* 65px / 16px */
}

.padding-69px-lr {
    padding-left: 4.3125rem;
    /* 69px / 16px */
    padding-right: 4.3125rem;
    /* 69px / 16px */
}

.padding-70px-lr {
    padding-left: 4.375rem;
    /* 70px / 16px */
    padding-right: 4.375rem;
    /* 70px / 16px */
}

.padding-75px-lr {
    padding-left: 4.6875rem;
    /* 75px / 16px */
    padding-right: 4.6875rem;
    /* 75px / 16px */
}

.padding-80px-lr {
    padding-left: 5rem;
    /* 80px / 16px */
    padding-right: 5rem;
    /* 80px / 16px */
}

.padding-85px-lr {
    padding-left: 5.3125rem;
    /* 85px / 16px */
    padding-right: 5.3125rem;
    /* 85px / 16px */
}

.padding-90px-lr {
    padding-left: 5.625rem;
    /* 90px / 16px */
    padding-right: 5.625rem;
    /* 90px / 16px */
}

.padding-95px-lr {
    padding-left: 5.9375rem;
    /* 95px / 16px */
    padding-right: 5.9375rem;
    /* 95px / 16px */
}

.padding-100px-lr {
    padding-left: 6.25rem;
    /* 100px / 16px */
    padding-right: 6.25rem;
    /* 100px / 16px */
}

.padding-140px-lr {
    padding-left: 8.75rem;
    /* 140px / 16px */
    padding-right: 8.75rem;
    /* 140px / 16px */
}

.no-padding {
    padding: 0 !important;
}

.no-padding-lr {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.no-padding-tb {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.no-padding-top {
    padding-top: 0 !important;
}

.no-padding-bottom {
    padding-bottom: 0 !important;
}

.no-padding-left {
    padding-left: 0 !important;
}

.no-padding-right {
    padding-right: 0 !important;
}

/* ===================================
    09. Display and overflow
====================================== */

.d-initial {
    display: initial !important;
}

.d-grid-sip-date {
    display: grid;
    grid-template-columns: repeat(7, auto);
}

.d-content {
    display: contents;
}

.overflow-hidden {
    overflow: hidden !important;
}

.overflow-visible {
    overflow: visible !important;
}

.overflow-auto {
    overflow: auto !important;
}

/* Width */

.width-24px {
    width: 1.5rem;
}

.width-18px {
    width: 1.125rem;
}

.width-20px {
    width: 1.25rem;
}

.w-55-px {
    width: 3.4375rem;
    /* 55px / 16px */
}

.w-70px {
    width: 4.375rem;
}

.w-100-px {
    width: 6.25rem;
    /* 100px / 16px */
}

.w-148-px {
    width: 9.25rem;
    /* 148px / 16px */
}

.w-150-px {
    width: 9.375rem;
    /* 150px / 16px */
}

.w-188-px {
    width: 11.75rem;
}

.w-348px {
    width: 21.75rem;
}

.w-520px {
    width: 32.5rem;
}

.w-16-point-5per {
    width: 16.5%;
}

.w-83-point-5per {
    width: 83.5%;
}

/* ===================================
    10. Position
====================================== */

.position-inherit {
    position: inherit !important;
}

/* top */

.top-auto {
    top: auto;
}

.top-inherit {
    top: inherit;
}

.top-0 {
    top: 0;
}

.top-1px {
    top: 0.0625rem;
    /* 1px / 16px */
}

.top-2px {
    top: 0.125rem;
    /* 2px / 16px */
}

.top-3px {
    top: 0.1875rem;
    /* 3px / 16px */
}

.top-4px {
    top: 0.25rem;
    /* 4px / 16px */
}

.top-5px {
    top: 0.3125rem;
    /* 5px / 16px */
}

.top-6px {
    top: 0.375rem;
    /* 6px / 16px */
}

.top-7px {
    top: 0.4375rem;
    /* 7px / 16px */
}

.top-8px {
    top: 0.5rem;
    /* 8px / 16px */
}

.top-9px {
    top: 0.5625rem;
    /* 9px / 16px */
}

.top-10px {
    top: 0.625rem;
    /* 10px / 16px */
}

.top-neg-10px {
    top: -0.625rem !important;
    /* 10px / 16px */
}

.top-11px {
    top: 0.688rem;
    /* 12px / 16px */
}

.top-neg-12px {
    top: -0.75rem;
}

.top-12px {
    top: 0.75rem;
    /* 12px / 16px */
}

.top-13px {
    top: 0.813rem;
}


.top-14px {
    top: 0.875rem;
}

.top-neg-14px {
    top: -0.875rem;
}

.top-neg-22px {
    top: -1.375rem !important;
}

.top-15px {
    top: 0.9375rem;
    /* 15px / 16px */
}

.top-16px {
    top: 1rem !important;
    /* 16px / 16px */
}

.top-neg-16px {
    top: -1rem;
}

.top-18px {
    top: 1.125rem;
}

.top-22per {
    top: 22%;
}

.top-neg-18px {
    top: -1.125rem;
}

.top-20px {
    top: 1.25rem;
    /* 20px / 16px */
}

.top-22px {
    top: 1.375rem;
    /* 20px / 16px */
}

.top-24px {
    top: 1.5rem;
}

.top-neg-24px {
    top: -1.5rem;
}

.top-25px {
    top: 1.5625rem;
    /* 25px / 16px */
}

.top-30px {
    top: 1.875rem;
    /* 30px / 16px */
}

.top-36px {
    top: 2.25rem;
    /* 35px / 16px */
}

.top-40px {
    top: 2.5rem;
    /* 40px / 16px */
}

.top-42px {
    top: 2.625rem;
}

.top-48px {
    top: 3rem;
}

.top-44px {
    top: 2.75rem;
}

.top-45px {
    top: 2.8125rem;
    /* 45px / 16px */
}

.top-50px {
    top: 3.125rem;
    /* 50px / 16px */
}

.top-60px {
    top: 3.75rem;
    /* 60px / 16px */
}

.top-70px {
    top: 4.375rem;
    /* 70px / 16px */
}

.top-80px {
    top: 5rem;
    /* 80px / 16px */
}

.top-88px {
    top: 5.5rem;
    /* 80px / 16px */
}


.top-90px {
    top: 5.625rem;
    /* 90px / 16px */
}

.top-92px {
    top: 5.75rem;
}

.top-100px {
    top: 6.25rem;
    /* 100px / 16px */
}

.top-110px {
    top: 6.875rem;
}

.top-112px {
    top: 7rem;
}

.top-auto {
    top: auto;
}

.top-inherit {
    top: inherit;
}

.top-32 {
    top: 32%;
}

.top-50 {
    top: 50%;
}

.top-40 {
    top: 40%;
}

/* bottom */
.bottom-0 {
    bottom: 0;
}

.bottom-8 {
    bottom: 0.5rem;
}

.bottom-17 {
    bottom: 1.063rem;
}

.bottom-30 {
    bottom: 1.875rem;
}

.bottom-70 {
    bottom: 4.375rem;
}

.bottom-auto {
    bottom: auto;
}

.bottom-57 {
    bottom: 57%;
}

.bottom-52 {
    bottom: 3.25rem;
}

.bottom-inherit {
    bottom: inherit;
}

.bottom-0px {
    bottom: 0;
}

.bottom-1px {
    bottom: 0.0625rem;
    /* 1px / 16px */
}

.bottom-2px {
    bottom: 0.125rem;
    /* 2px / 16px */
}

.bottom-3px {
    bottom: 0.1875rem;
    /* 3px / 16px */
}

.bottom-4px {
    bottom: 0.25rem;
    /* 4px / 16px */
}

.bottom-neg-4px {
    bottom: -0.25rem;
}

.bottom-5px {
    bottom: 0.3125rem;
    /* 5px / 16px */
}

.bottom-6px {
    bottom: 0.375rem;
    /* 6px / 16px */
}

.bottom-7px {
    bottom: 0.4375rem;
    /* 7px / 16px */
}

.bottom-8px {
    bottom: 0.5rem;
    /* 8px / 16px */
}

.bottom-9px {
    bottom: 0.5625rem;
    /* 9px / 16px */
}

.bottom-10px {
    bottom: 0.625rem;
    /* 10px / 16px */
}

.bottom-14px {
    bottom: 0.875rem;
    /* 10px / 16px */
}

.bottom-16px {
    bottom: 1rem;
    /* 15px / 16px */
}

.bottom-18px {
    bottom: 1.125rem;
    /* 15px / 16px */
}

.bottom-20px {
    bottom: 1.25rem;
    /* 20px / 16px */
}

.bottom-22px {
    bottom: 1.375rem;
}

.bottom-neg-22px {
    bottom: -1.375rem;
}

.bottom-24px {
    bottom: 1.5rem;
    /* 25px / 16px */
}

.bottom-26px {
    bottom: 1.625rem;
    /* 25px / 16px */
}

.bottom-30px {
    bottom: 1.875rem;
    /* 30px / 16px */
}

.bottom-34px {
    bottom: 2.125rem;
    /* 35px / 16px */
}

.bottom-40px {
    bottom: 2.5rem;
    /* 40px / 16px */
}

.bottom-45px {
    bottom: 2.8125rem;
    /* 45px / 16px */
}

.bottom-50px {
    bottom: 3.125rem;
    /* 50px / 16px */
}

.bottom-52px {
    bottom: 3.25rem;
    /* 50px / 16px */
}


.bottom-56px {
    bottom: 3.5rem;
    /* 55px / 16px */
}

.bottom-60px {
    bottom: 3.75rem;
    /* 60px / 16px */
}

.bottom-65px {
    bottom: 4.0625rem;
    /* 65px / 16px */
}

.bottom-70px {
    bottom: 4.375rem;
    /* 70px / 16px */
}

.bottom-75px {
    bottom: 4.6875rem;
    /* 75px / 16px */
}

.bottom-80px {
    bottom: 5rem;
    /* 80px / 16px */
}

.bottom-85px {
    bottom: 5.3125rem;
    /* 85px / 16px */
}

.bottom-90px {
    bottom: 5.625rem;
    /* 90px / 16px */
}

.bottom-95px {
    bottom: 5.9375rem;
    /* 95px / 16px */
}

.bottom-100px {
    bottom: 6.25rem;
    /* 100px / 16px */
}

.bottom-auto {
    bottom: auto;
}

.bottom-57 {
    bottom: 3.5625rem;
    /* 57% based on a parent's height */
}

.bottom-inherit {
    bottom: inherit;
}

/* right */

.right-15px-negative {
    right: -0.9375rem;
    /* -15px / 16px */
}

.right-5px-negative {
    right: -0.3125rem;
    /* -5px / 16px */
}

.right-0px {
    right: 0;
}

.right-1px {
    right: 0.0625rem;
    /* 1px / 16px */
}

.right-2px {
    right: 0.125rem;
    /* 2px / 16px */
}

.right-3px {
    right: 0.1875rem;
    /* 3px / 16px */
}

.right-4px {
    right: 0.25rem;
    /* 4px / 16px */
}

.right-5px {
    right: 0.3125rem;
    /* 5px / 16px */
}

.right-6px {
    right: 0.375rem;
    /* 6px / 16px */
}

.right-7px {
    right: 0.4375rem;
    /* 7px / 16px */
}

.right-8px {
    right: 0.5rem;
    /* 8px / 16px */
}

.right-9px {
    right: 0.5625rem;
    /* 9px / 16px */
}

.right-10px {
    right: 0.625rem;
    /* 10px / 16px */
}

.right-12px {
    right: 0.75rem;
}

.right-14px {
    right: 0.875rem;
    /* 14px / 16px */
}

.right-15px {
    right: 0.9375rem;
    /* 15px / 16px */
}

.right-16px {
    right: 1rem;
    /* 16px / 16px */
}

.right-20px {
    right: 1.25rem;
    /* 20px / 16px */
}

.right-24px {
    right: 1.5rem;
    /* 25px / 16px */
}

.right-28px {
    right: 1.75rem;
    /* 28px / 16px */
}

.right-30px {
    right: 1.875rem;
    /* 30px / 16px */
}

.right-35px {
    right: 2.1875rem;
    /* 35px / 16px */
}

.right-36px {
    right: 2.25rem;
    /* 35px / 16px */
}

.right-40px {
    right: 2.5rem;
    /* 40px / 16px */
}

.right-45px {
    right: 2.8125rem;
    /* 45px / 16px */
}

.right-50px {
    right: 3.125rem;
    /* 50px / 16px */
}

.right-55px {
    right: 3.4375rem;
    /* 55px / 16px */
}

.right-60px {
    right: 3.75rem;
    /* 60px / 16px */
}

.right-64px {
    right: 4rem;
    /* 60px / 16px */
}

.right-80px {
    right: 5rem;
    /* 80px / 16px */
}

.right-108px {
    right: 6.75rem;
    /* 80px / 16px */
}

.right-auto {
    right: auto;
}

.right-inherit {
    right: inherit;
}

/* left */
.left-0 {
    left: 0;
}

.left-44 {
    left: 44%;
}

.left-45 {
    left: 45% !important;
}

.left-46 {
    left: 2.875rem;
}

.left-50 {
    left: 50%;
}

.left-auto {
    left: auto;
}

.left-inherit {
    left: inherit;
}

.left-18px-neg {
    left: -1.125rem !important;
    /* 16px / 16px */
}

.left-0px {
    left: 0;
}

.left-1px {
    left: 0.0625rem;
    /* 1px / 16px */
}

.left-2px {
    left: 0.125rem;
    /* 2px / 16px */
}

.left-3px {
    left: 0.1875rem;
    /* 3px / 16px */
}

.left-4px {
    left: 0.25rem;
    /* 4px / 16px */
}

.left-5px {
    left: 0.3125rem;
    /* 5px / 16px */
}

.left-6px {
    left: 0.375rem;
    /* 6px / 16px */
}

.left-7px {
    left: 0.4375rem;
    /* 7px / 16px */
}

.left-8px {
    left: 0.5rem;
    /* 8px / 16px */
}

.left-9px {
    left: 0.5625rem;
    /* 9px / 16px */
}

.left-10px {
    left: 0.625rem;
    /* 10px / 16px */
}

.left-12px {
    left: 0.75rem;
    /* 10px / 16px */
}

.left-15px {
    left: 0.9375rem;
    /* 15px / 16px */
}

.left-16px {
    left: 1rem;
    /* 16px / 16px */
}

.left-20px {
    left: 1.25rem;
    /* 20px / 16px */
}

.left-24px {
    left: 1.5rem;
}

.left-25px {
    left: 1.5625rem;
    /* 25px / 16px */
}

.left-30px {
    left: 1.875rem;
    /* 30px / 16px */
}

.left-32px {
    left: 2rem;
    /* 30px / 16px */
}


.left-35px {
    left: 2.1875rem;
    /* 35px / 16px */
}

.left-40px {
    left: 2.5rem;
    /* 40px / 16px */
}

.left-45px {
    left: 2.8125rem;
    /* 45px / 16px */
}

.left-50px {
    left: 3.125rem;
    /* 50px / 16px */
}

.left-44 {
    left: 2.75rem;
    /* 44% based on a parent's width */
}

.left-45 {
    left: 2.8125rem;
    /* 45% based on a parent's width */
}

.left-61 {
    left: 3.8125rem;
    /* 61% based on a parent's width */
}

.left-96px {
    left: 6rem;
    /* 61% based on a parent's width */
}


.left-110px {
    left: 6.875rem;
}

.left-auto {
    left: auto;
}

.left-inherit {
    left: inherit;
}

/* ===================================
    11. Width
====================================== */
.w-0 {
    width: 0;
}

.w-1rem {
    width: 0.063rem !important;
}

.w-10 {
    width: 10%;
}

.w-15 {
    width: 15%;
}

.w-20 {
    width: 20%;
}

.w-25 {
    width: 25%;
}

.w-30 {
    width: 30%;
}

.w-35 {
    width: 35%;
}

.w-40 {
    width: 40%;
}

.w-45 {
    width: 45%;
}

.w-50 {
    width: 50%;
}

.w-55 {
    width: 55%;
}

.w-57 {
    width: 57%;
}

.w-60 {
    width: 60%;
}

.w-65 {
    width: 65%;
}

.w-70 {
    width: 70%;
}

.w-75 {
    width: 75%;
}

.w-80 {
    width: 80%;
}

.w-85 {
    width: 85%;
}

.w-90 {
    width: 90%;
}

.w-95 {
    width: 95%;
}

.w-100 {
    width: 100%;
}

.w-auto {
    width: auto;
}

.w-0px {
    width: 0;
}

.w-1px {
    width: 0.0625rem;
    /* 1px / 16px */
}

.w-2px {
    width: 0.125rem;
    /* 2px / 16px */
}

.w-3px {
    width: 0.1875rem;
    /* 3px / 16px */
}

.w-4px {
    width: 0.25rem;
    /* 4px / 16px */
}

.w-5px {
    width: 0.3125rem;
    /* 5px / 16px */
}

.w-6px {
    width: 0.375rem;
    /* 6px / 16px */
}

.w-7px {
    width: 0.4375rem;
    /* 7px / 16px */
}

.w-8px {
    width: 0.5rem;
    /* 8px / 16px */
}

.w-9px {
    width: 0.5625rem;
    /* 9px / 16px */
}

.w-10px {
    width: 0.625rem;
    /* 10px / 16px */
}

.w-15px {
    width: 0.9375rem;
    /* 15px / 16px */
}

.w-16px {
    width: 1rem;
    /* 16px / 16px */
}

.w-20px {
    width: 1.25rem;
    /* 20px / 16px */
}

.w-24px {
    width: 1.5rem;
    /* 24px / 16px */
}


.w-25px {
    width: 1.5625rem;
    /* 25px / 16px */
}

.w-26px {
    width: 1.625rem;
    /* 26px / 16px */
}

.w-30px {
    width: 1.875rem;
    /* 30px / 16px */
}

.w-32px {
    width: 2rem;
    /* 32px / 16px */
}

.w-35px {
    width: 2.1875rem;
    /* 35px / 16px */
}

.w-40px {
    width: 2.5rem;
    /* 40px / 16px */
}

.w-44px {
    width: 2.75rem;
    /* 44px / 16px */
}


.w-45px {
    width: 2.8125rem;
    /* 45px / 16px */
}

.w-48px {
    width: 3rem;
    /* 48px / 16px */
}

.w-50px {
    width: 3.125rem;
    /* 50px / 16px */
}

.w-55px {
    width: 3.4375rem;
    /* 55px / 16px */
}

.w-60px {
    width: 3.75rem;
    /* 60px / 16px */
}

.w-66px {
    width: 4.125rem;
}

.w-65px {
    width: 4.0625rem;
    /* 65px / 16px */
}

.w-70px {
    width: 4.375rem;
    /* 70px / 16px */
}

.w-75px {
    width: 4.6875rem;
    /* 75px / 16px */
}

.w-78px {
    width: 4.875rem;
    /* 78px / 16px */
}

.w-80px {
    width: 5rem;
    /* 80px / 16px */
}

.w-85px {
    width: 5.3125rem;
    /* 85px / 16px */
}

.w-86px {
    width: 5.375rem;
    /* 86px / 16px */
}

.w-90px {
    width: 5.625rem;
    /* 90px / 16px */
}

.w-95px {
    width: 5.9375rem;
    /* 95px / 16px */
}

.w-100px {
    width: 6.25rem;
    /* 100px / 16px */
}

.w-110px {
    width: 6.875rem;
    /* 110px / 16px */
}

.w-120px {
    width: 7.5rem;
    /* 120px / 16px */
}

.w-130px {
    width: 8.125rem;
    /* 130px / 16px */
}

.w-140px {
    width: 8.75rem;
    /* 140px / 16px */
}

.w-150px {
    width: 9.375rem;
    /* 150px / 16px */
}

.w-160px {
    width: 10rem;
    /* 160px / 16px */
}

.w-170px {
    width: 10.625rem;
    /* 170px / 16px */
}

.w-180px {
    width: 11.25rem;
    /* 180px / 16px */
}

.w-190px {
    width: 11.875rem;
    /* 190px / 16px */
}

.w-200px {
    width: 12.5rem;
    /* 200px / 16px */
}

.w-235px {
    width: 14.688rem;
    /* 200px / 16px */
}


.w-250px {
    width: 15.625rem;
    /* 250px / 16px */
}

.w-248px {
    width: 15.5rem;
}

.w-300px {
    width: 18.75rem;
    /* 300px / 16px */
}

.w-310px {
    width: 19.375rem;
}

.w-350px {
    width: 21.875rem;
    /* 350px / 16px */
}

.w-374px {
    width: 23.375rem;
}


.w-400px {
    width: 25rem;
    /* 400px / 16px */
}

.w-450px {
    width: 28.125rem;
    /* 450px / 16px */
}

.w-460px {
    width: 28.75rem;
    /* 450px / 16px */
}

.w-480px {
    width: 30rem;
}

.w-500px {
    width: 31.25rem;
    /* 500px / 16px */
}

.w-540px {
    width: 33.75rem;
}

.w-550px {
    width: 34.375rem;
    /* 550px / 16px */
}

.w-600px {
    width: 37.5rem;
    /* 600px / 16px */
}

.w-650px {
    width: 40.625rem;
    /* 650px / 16px */
}

.w-700px {
    width: 43.75rem;
    /* 700px / 16px */
}

.w-750px {
    width: 46.875rem;
    /* 750px / 16px */
}

.w-800px {
    width: 50rem;
    /* 800px / 16px */
}

.w-850px {
    width: 53.125rem;
    /* 850px / 16px */
}

.w-900px {
    width: 56.25rem;
    /* 900px / 16px */
}

.w-950px {
    width: 59.375rem;
    /* 950px / 16px */
}

.w-1000px {
    width: 62.5rem;
    /* 1000px / 16px */
}

.w-10 {
    width: 1.625rem;
    /* 10% of the parent container's width */
}

.w-15 {
    width: 2.5rem;
    /* 15% of the parent container's width */
}

.w-20 {
    width: 3.125rem;
    /* 20% of the parent container's width */
}

.w-24 {
    width: 1.5rem;
}

.w-25 {
    width: 3.90625rem;
    /* 25% of the parent container's width */
}


.w-32 {
    width: 2rem;
}

.w-35 {
    width: 5.46875rem;
    /* 35% of the parent container's width */
}

.w-38 {
    width: 2.375rem;
}

.w-45 {
    width: 7.03125rem;
    /* 45% of the parent container's width */
}

.w-50 {
    width: 7.8125rem;
    /* 50% of the parent container's width */
}

.w-55 {
    width: 8.59375rem;
    /* 55% of the parent container's width */
}

.w-57 {
    width: 9.0625rem;
    /* 57% of the parent container's width */
}

.w-60 {
    width: 9.375rem;
    /* 60% of the parent container's width */
}

.w-65 {
    width: 10.46875rem;
    /* 65% of the parent container's width */
}

.w-70 {
    width: 11.5625rem;
    /* 70% of the parent container's width */
}

.w-75 {
    width: 12.65625rem;
    /* 75% of the parent container's width */
}

.w-80 {
    width: 13.75rem;
    /* 80% of the parent container's width */
}

.w-80-per {
    width: 80%;
    /* 80% of the parent container's width */
}

.w-85 {
    width: 14.84375rem;
    /* 85% of the parent container's width */
}

.w-90 {
    width: 15.9375rem;
    /* 90% of the parent container's width */
}

.w-95 {
    width: 17.03125rem;
    /* 95% of the parent container's width */
}

.w-72 {
    width: 72%;
}

.w-270 {
    width: 16.875rem !important;
}

.w-100 {
    width: 18.125rem;
    /* 100% of the parent container's width */
}

.w-300 {
    width: 300px;
}

.w-auto {
    width: auto;
}

.w-max-content {
    width: max-content !important;
}

/* ===================================
    12. Height
====================================== */
.h-max-content {
    height: max-content !important;
}

.h-point-5px {
    height: 0.031rem;
}

.h-1 {
    height: 0.063rem;
}

.h-2 {
    height: 0.125rem;
}

.h-6 {
    height: 0.375rem;
}

.h-20 {
    height: 1.25rem;
}

.h-24 {
    height: 1.5rem;
}

.h-28 {
    height: 1.75rem;
}

.h-16 {
    height: 1rem;
}

.h-20 {
    height: 1.25rem;
}

.h-22 {
    height: 1.375rem;
}

.h-24 {
    height: 1.5rem;
}

.h-28 {
    height: 1.75rem;
}

.h-30 {
    height: 1.875rem;
}

.h-40 {
    height: 2.5rem;
}

.h-44 {
    height: 2.75rem;
}

.h-46 {
    height: 2.875rem;
}


.h-48 {
    height: 3rem;
}

.h-50 {
    height: 3.125rem;
}

.h-52 {
    height: 3.25rem;
}

.h-54 {
    height: 3.375rem;
}

.h-57 {
    height: 3.563rem;
}

.h-64 {
    height: 4rem;
}

.h-66px {
    height: 4.125rem;
}

.h-70px {
    height: 4.375rem;
}

.h-72 {
    height: 4.5rem;
}

.h-74 {
    height: 4.625rem;
}

.h-76 {
    height: 4.75rem;
}

.h-78 {
    height: 4.875rem;
}

.h-80 {
    height: 5rem;
}

.h-90 {
    height: 5.625rem;
}

.h-98px {
    height: 6.125rem;
}

.h-100px {
    height: 6.25rem;
}

.h-104px {
    height: 6.5rem;
}

.h-112px {
    height: 7rem;
}

.h-106px {
    height: 6.625rem;
}

.h-120px {
    height: 7.5rem;
}

.h-130px {
    height: 8.125rem;
}

.h-140px {
    height: 8.75rem;
}


.h-146 {
    height: 9.125rem;
}

.h-175 {
    height: 10.938rem;
}

.h-182 {
    height: 11.375rem;
}

.h-190 {
    height: 11.875rem;
}

.h-198 {
    height: 12.375rem;
}

.h-206 {
    height: 12.875rem;
}

.h-212 {
    height: 13.25rem;
}

.h-240 {
    height: 15rem;
}

.h-250 {
    height: 15.625rem;
}

.h-328 {
    height: 20.5rem;
}

.h-350 {
    height: 350px;
}


.h-max-500px {
    max-height: 31.25rem;
}

.h-max-600px {
    max-height: 37.5rem;
}

.h-70 {
    min-height: 70% !important;
}

.min-h20vh {
    min-height: 20vh !important;
}

.min-h40vh {
    min-height: 40vh !important;
}

.min-h90vh {
    min-height: 90vh !important;
}

.min-h80vh {
    min-height: 80vh !important;
}

.h95vh {
    height: 95vh !important;
}

.h-100 {
    height: 100% !important;
}

.h-auto {
    height: auto !important;
}

.h-fit-content {
    height: fit-content !important;
}

.max-h-100 {
    max-height: 100%;
}

.max-h-inherit {
    max-height: inherit !important;
}

.max-h-130px {
    max-height: 8.125rem;
}

.h-50vh {
    height: 50vh;
}

.h-70vh {
    height: 70vh;
}

.h-80vh {
    height: 80vh;
}

.min-h-60vh {
    min-height: 60vh;
}

.h-100vh {
    height: 100vh;
}

/* min height */
.min-h-100vh {
    min-height: 100vh;
}

.justify-content-space-between {
    justify-content: space-between;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-end {
    justify-content: end;
}


.justify-content-start {
    justify-content: start;
}

//flex direction

.e-flex-direction-column {
    flex-direction: column;
}

// backdrop

.e-backdrop-50 {
    backdrop-filter: blur(50px);
}

/*card*/

//background
.bg-transparent {
    background: transparent !important;
}

.e-hover-btn {
    padding: 6px !important;
}

.e-sip-date:hover {
    background-color: #8a9ae2;
    color: #fff;
}

.object-fit-cover {
    object-fit: cover;
}

.vertical-align-sub {
    vertical-align: sub;
}

.e-align-center {
    align-items: center;
}

.e-alignflex-start {
    align-items: flex-start;
}

.e-align-item-baseline {
    align-items: baseline;
}

//truncate
.truncate-first-line {
    display: -webkit-box;
    white-space: normal !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}

.truncate-second-line {
    display: -webkit-box;
    white-space: normal !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

.truncate-third-line {
    display: -webkit-box;
    white-space: normal !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}

.truncate-four-line {
    display: -webkit-box;
    white-space: normal !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
}

.e-flex-wrap {
    flex-wrap: wrap;
}



// gap

.gap-12px {
    gap: 0.75rem;
}

.gap-2-all {
    gap: 0.125rem;
}

.gap-0-6px {
    gap: 0 0.375rem;
}

.gap-0-8 {
    gap: 0 0.5rem;
}

.gap-4-8 {
    gap: 0.25rem 0.5rem;
}

.gap-0-10 {
    gap: 0 0.625rem;
}

.e-gap-0-12px {
    gap: 0 0.75rem;
}

.e-gap-0-14 {
    gap: 0 0.875rem;
}

.e-gap-0-16 {
    gap: 0 1rem;
}

.e-gap-8-16 {
    gap: 0.5rem 1rem;
}


.e-gap-0-18px {
    gap: 1.125rem 0;
}

.gap-0-24 {
    gap: 0 1.5rem;
}

.gap-0-12px {
    gap: 0 0.75rem;
}

.gap-24-0 {
    gap: 1.5rem 0;
}

.gap-0-26px {
    gap: 0 1.625rem;
}

.gap-26px {
    gap: 1.625rem;
}

.gap-0-72 {
    gap: 0 4.5rem;
}

.gap-0-120 {
    gap: 0 7.5rem;
}

.gap-0-132 {
    gap: 0 8.25rem;
}

.row-gap-32px {
    row-gap: 2rem;
}

.row-gap-40px {
    row-gap: 2.5rem;
}

.e-inline-flex {
    display: inline-flex;
    align-items: center;
}

.e-display-flex {
    display: flex;
    align-items: center;
}

.e-error-message {
    color: $red;
    // padding-top: 2px !important;
}

.e-disable {
    opacity: 0.5 !important;
    pointer-events: none;
}

.slider-track-0 {
    background-color: $egyptian_blue !important;
}

//Box shadow

.e-box-shadow-filter-modal {
    box-shadow: 0 0.5rem 0.625rem -0.375rem rgba(0, 0, 0, 0.10), 0 1.25rem 1.563rem -0.313rem rgba(0, 0, 0, 0.10);
}

.card-shadow {
    &:hover {
        filter: drop-shadow(0 0.25rem 0.625rem rgba(0, 0, 0, 0.15));


    }
}

// //arrow animation 
// .e-btn-arrow:hover {
//     transition: all .3s ease-in-out;
//     .e-arrow-hover {
//         transform: translateX(6px);
//     }
// }


// /pagination

.e-pagination {
    display: flex;
    align-items: center;
    gap: 0 1rem;
    padding-left: 0;
}

.e-pagination li {
    display: flex;
    align-items: center;
    border-radius: 40px;
    padding: 5px;
    background: linear-gradient(0deg, $bright_gray 0%, $bright_gray 100%), linear-gradient(248deg, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.20) 100%), linear-gradient(248deg, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.20) 100%), linear-gradient(248deg, rgba(217, 207, 231, 0.70) 0%, rgba(211, 218, 253, 0.70) 100%);
    cursor: pointer;
}

.e-page-item a {
    color: $charleston_green !important;
    font-family: "Inter" !important;
    font-size: 1rem !important;
    font-weight: 500 !important;
    text-decoration: none;
    padding: 0.25rem 0.75rem;
}

.break a {
    color: $charleston_green !important;
    font-family: "Inter" !important;
    font-size: 1rem !important;
    font-weight: 500 !important;
    text-decoration: none;
    padding: 0.25rem 0.625rem;
}

.e-pagination .e-next-button a {
    text-decoration: none !important;
}

.e-pagination .e-prev-button a {
    text-decoration: none !important;
}

.e-pagination .e-prev-button img {
    rotate: 180deg;
}

.e-pagination .e-active {
    border-radius: 2.5rem;
    background: linear-gradient(0deg, $egyptian_blue 0%, $egyptian_blue 100%), linear-gradient(248deg, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.20) 100%), linear-gradient(248deg, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.20) 100%), linear-gradient(248deg, rgba(217, 207, 231, 0.70) 0%, rgba(211, 218, 253, 0.70) 100%);
    backdrop-filter: blur(3.875rem);
}

.e-pagination .e-active a {
    color: $white !important;
}


.e-next-button.disabled {
    a {
        opacity: 0.5;
    }

    pointer-events: none;
}

.e-prev-button.disabled {
    a {
        opacity: 0.5;
    }

    pointer-events: none;
}

.horizontal-rule {
    height: 0.125rem;
    // width: 12rem;
    width: 20rem;
    background: $light_silver;
}

//Gauge chart 

.e-gauge-chart {
    .needle {
        display: none;
    }
}

.value-text text {
    display: none !important;
}

.tick-value {
    display: none;
}

.tick-line {
    display: none;
}

// button hover

.e-primary-button-hover:hover {
    background: $denim_blue;
}

.e-secondary-button-hover:hover {
    background: $egyptian_blue !important;
    border: 0.063rem solid $white;
    color: $white;
}

.modal-lg {
    --bs-modal-width: 56.25rem;
}

// modal styles
.e-modal-box-shadow {
    box-shadow: 0 0.5rem 0.625 -0.375rem rgba(0, 0, 0, 0.10), 0 1.25rem 1.563rem -0.313rem rgba(0, 0, 0, 0.10);
}

.e-how-works-box-shadow {
    box-shadow: 0px 8px 10px -6px #0000001A;
}

.modal-gradient-bg {
    background: url("../Assets/Images/modal-gradient-bg.webp");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}


// Basket detail page 
.basket-why-invest-bg {
    background: url("../Assets/Images/basket-why-invest-bg.webp") no-repeat;
    background-size: cover;
    background-position: right bottom;
}

// Manage family
#manage-add-family {
    background: url("../Assets/Images/add-family-card-bg.webp") no-repeat;
    background-size: cover;
    background-position: right bottom;
}

// About us
.about-us {
    background: url("../Assets/Images/AboutUs/about-us-banner-bg.webp") no-repeat;
    background-size: 100% 100%;
}


// FAQ
.faq-tab {
    border-left: 0.25rem solid transparent;

    &:hover {
        border-radius: 0 0.75rem 0.75rem 0;
        background: $light_blue_opacity_05;
        color: $egyptian_blue !important;
        font-weight: 500 !important;
    }
}

#profile-tab {
    &:hover {
        background: $light_blue_opacity_05;
    }
}

.active-tab {
    border-radius: 0 0.75rem 0.75rem 0;
    background: $light_blue_opacity_05;
    border-left: 0.25rem solid $egyptian_blue;
    color: $egyptian_blue;
    font-weight: 500 !important;
}

// Accordion style

.accordion {
    --bs-accordion-bg: transparent;
    --bs-accordion-active-color: $egyptian_blue !important;
    // --bs-accordion-border-color: #d1d1d663;
    --bs-accordion-border-color: $egyptian_blue;
    --bs-accordion-btn-icon: url("../Assets/Images/faq-accordion-arrow.svg");
    --bs-accordion-btn-active-icon: url("../Assets/Images/faq-accordion-arrow.svg");
    --bs-accordion-btn-icon-width: 2em;

}

.accordion-item {
    border-bottom: 1px solid $light_gray_opacity_05;
}

.accordion-button:not(.collapsed) {
    color: $egyptian_blue;
}


// modal scroll 
.e-manage-modal .modal-body::-webkit-scrollbar-thumb {
    background: $egyptian_blue !important;
    /* padding: 10px; */
    border-radius: 2rem !important;
}

.e-manage-modal .modal-body::-webkit-scrollbar {
    width: 0.25rem !important;
    display: block !important;
}

.e-amc-wrapper::-webkit-scrollbar-thumb {
    background: $egyptian_blue !important;
    border-radius: 2rem !important;
}

.e-amc-wrapper::-webkit-scrollbar {
    width: 0.25rem !important;
}

// Portfolio Page
.import-portfolio {
    background: url("../Assets/Images/external-portfolio.webp") no-repeat;
    background-size: 100% 100%;
    border-radius: 1rem;
}

// Left Navigation 
.e-nav-item {
    border-left: 0.25rem solid transparent;
    transition: all 0.1s ease-in-out;
    border-radius: 0 0.75rem 0.75rem 0;

    &:hover {
        background: $light_blue_opacity_05;
    }

    &.active {
        background: $light_blue_opacity_05;
        border-color: $egyptian_blue;
        cursor: auto;

        p {
            color: $egyptian_blue !important;
            font-weight: 600 !important;
        }
    }
}

//overlay
.overlay-background {
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    cursor: pointer;
    z-index: 9;
    overflow: hidden !important;
}

// .overlay-container {
//     background-color: #fff;
//     // position: absolute;
//     // border-radius: 24px;
//     // top: 0;
//     // right: 0;
//     // bottom: 0;
//     // left: 0;
//     // margin: auto;
//     // z-index: 99;
//     // width: fit-content;
//     // height: fit-content;
//     // overflow: hidden;
// }

.body-no-scroll {
    overflow: hidden !important;
}

.audio-player,
.video-player {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.audio-player,
.video-player,
.recorded-player {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.live-player {
    height: 200px;
    width: 400px;
    border: 1px solid #646cff;
    margin-bottom: 30px;
}

.e-over-flow-auto {
    overflow: auto;
}

//manage-bank modal scroll 
.e-offcanvas-wrapper::-webkit-scrollbar-thumb {
    background: $egyptian_blue !important;
    border-radius: 2rem !important;
}

.e-offcanvas-wrapper::-webkit-scrollbar {
    width: 0.25rem !important;
    display: block !important;
}


//manage-bank modal scroll 
.e-manage-scrollbar::-webkit-scrollbar-thumb {
    background: $egyptian_blue;
    border-radius: 50rem;
}

.e-manage-scrollbar::-webkit-scrollbar {
    width: 0.25rem;
    height: 0.25rem;
    display: block;
}

.aspect-ratio-three-two {
    width: 100%;
}

.background-fit {

    background-repeat: no-repeat;
    background-size: 100% 100%;
}

#explore-search {
    &::placeholder {
        font-size: 1rem;
        color: $philippine_gray;
        line-height: 1.5rem;
    }
}

#footer-links {
    &:hover {
        color: $egyptian_blue !important;
    }
}

// Border for Active tabs
.show-border {
    position: relative;

    &::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 0.125rem;
        background-color: $egyptian-blue;
    }
}

// Preserving full width and height when device zoom level changes
.offcanvas-backdrop,
.modal-backdrop {
    height: 100%;
    width: 100%;
}


// ZOOM Level
.e-app-zoom-level-7 {
    zoom: 0.6 !important;
}

.e-app-zoom-level-8 {
    zoom: 0.8 !important;
}

.e-app-zoom-level-9 {
    zoom: 0.9 !important;
}

// Notification icon 

#notification {
    background: $light_blue_opacity_04;

    &:hover {
        background: $light_blue;
    }
}

// React date range picker
.e-filter-date-picker {
    top: 120%;
    right: 0;
    z-index: 2;
    box-shadow: 0 1rem 2rem rgba(23, 37, 76, 0.12);
    border-radius: 1rem;
    overflow: hidden;
    // width: fit-content;

    .rdrDefinedRangesWrapper {
        padding: 1rem 1.875rem 1rem 0.75rem;
        font-size: 0.875rem;
        width: 9.8125rem;
        border-right: 0.0625rem solid $bright-gray;

        .rdrStaticRange {
            color: $charleston_green;
            font-family: "Poppins";

            .rdrStaticRangeLabel {
                line-height: 1.25rem;
                padding: 0.5rem 0 0.5rem 0.5rem;
            }

            &:hover {
                .rdrStaticRangeLabel {
                    color: $egyptian_blue;
                    background: $light_blue_opacity_05;
                    border-radius: 0.25rem;
                }
            }
        }

        .rdrStaticRange.rdrStaticRangeSelected {
            border-radius: 0.25rem;
            background-color: $egyptian_blue !important;
            span {
                color: $white;
                font-weight: 400;
            }

            &:hover {
                span {
                    background-color: $egyptian_blue;
                    color: $white;
                     font-weight: 400;
                }
            }
        }
    }

    .rdrDateRangeWrapper {
        border-top-right-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        position: relative;

        .rdrMonths {
            margin-top: 1.875rem;

            .rdrMonth {
                padding-left: 1rem;
                padding-right: 1rem;
                // width: 16.75rem;

                .rdrMonthName {
                    color: $black;
                    font-family: "Poppins";
                    font-size: 0.875rem;
                    padding: 0rem 0.4em;
                    text-align: center;
                    margin-bottom: 0.5rem;
                }

                .rdrWeekDays {
                    .rdrWeekDay {
                        color: $black;
                        font-family: "Poppins";
                        font-size: 0.875rem;
                    }
                }

                .rdrDays {
                    .rdrDay {
                        color: $black;
                        font-family: "Poppins";
                        font-size: 0.75rem;
                        line-height: 0.75rem;

                        .rdrStartEdge,
                        .rdrEndEdge {
                            background-color:$egyptian_blue;
                            // background-color: $light_blue_opacity_05;
                            color: $white;
                            border-radius: 0;
                        }

                        .rdrInRange {
                            color: $white ;
                            // background-color: $light_blue_opacity_05;
                            background-color:$egyptian_blue;
                            border-radius: 0;
                        }

                        .rdrDayStartPreview,
                        .rdrDayEndPreview {
                            background-color: transparent;
                        }

                        .rdrDayStartPreview,
                        .rdrDayEndPreview,
                        .rdrDayInPreview {
                            border: none;
                        }
                    }

                    .rdrDayToday {
                        .rdrDayNumber span {
                            font-weight: 300 !important;
                        }
                    }

                    .rdrDayToday:has(.rdrEndEdge),
                    .rdrDayToday:has(.rdrStartEdge) {
                        border: none;
                        color: $white;
                    }

                    .rdrDayToday .rdrDayNumber span:after {
                        background: transparent;
                    }

                    .rdrDay:has(.rdrStartEdge),
                    .rdrDay:has(.rdrEndEdge) {
                        background-color:$egyptian_blue;
                    }

                    .rdrDay:has(.rdrInRange) {
                       background-color:$egyptian_blue;
                    }

                    .rdrDayPassive {
                        pointer-events: unset;
                    }

                    .rdrDayPassive span {
                        color: $black;
                        
                    }

                    .rdrDayPassive:has(.rdrEndEdge) {
                        .rdrDayNumber span {
                            color: $white;
                        }
                    }

                    .rdrDayPassive {
                        .rdrDayNumber span {
                            color: $white;
                        }
                    }

                    .rdrDay:not(.rdrDayPassive) .rdrInRange~.rdrDayNumber span {
                        color: $white;
                    }

                    .rdrDay:not(.rdrDayPassive) .rdrStartEdge~.rdrDayNumber span,
                    .rdrDay:not(.rdrDayPassive) .rdrEndEdge~.rdrDayNumber span {
                         color: $white;
                    }
                }
            }
        }

        .e-custom-date-navigator {
            top: 1.875rem;

            .e-left-icon {
                position: absolute;
                left: 2.75rem;
                background-color: transparent;
                border: none;
                padding: 0;
                top: 0;
            }

            .e-right-icon {
                position: absolute;
                right: 3.375rem;
                background-color: transparent;
                border: none;
                padding: 0;
                top: 0;
            }
        }
    }
}

;

.e-works-li {
    li {
        &::marker {
            font-size: 12px;
        }
    }
};


// Login Sign up page 
.gradient-border-line {
    height: 1px;
    background: linear-gradient(90deg, #C4C6C8 0%, #FFFFFF 100%);
};


// phone input plugin
.PhoneInput {
    display: flex;
    align-items: center;
    border-radius: 0.75rem;
    border: 0.063rem solid $charleston_green;
  
    font-size: 1rem;
    color: $dark_charcoal;
    font-family: "Poppins";
    font-weight: 400;
    line-height: 1.375rem;

  input {
        background: transparent;
        font-size: 1rem;
        color: $dark_charcoal;
        font-family: "Poppins";
        font-weight: 400;
        line-height: 1.375rem;
        border: none;
        outline: none;
        padding-block: 0.63rem;
        padding-right: 1rem;
        caret-color: $dark_charcoal;
        &::placeholder  {
            font-size: 0.875rem;
        }
    }

}

.PhoneInput--focus {
        border: 0.063rem solid $egyptian_blue;
}

.gradient-vr-line {
    position: relative;
    &::after {
        content: "";
        position: absolute;
        left: -5px;
        top: 50%;
        transform: translateY(-50%);
        height: 300px;
        width: 1px;
        background-image: url("../Assets/Images/Login/gradient-vr-line.svg");
    }
}

.hover-blue-link {
    color: #1E1F24;
    &:hover {
        color:$egyptian-blue
    }
}